import React, { useState, useRef, useEffect } from 'react';
import { FaClock } from 'react-icons/fa';

const TimeInput = ({ time, setTime }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  // Handle closing the dropdown when clicking outside
  const closeDropdown = (e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      !inputRef.current.contains(e.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, []);

  // Helper function to convert 24-hour format to 12-hour format with AM/PM
  const convertTo12HourFormat = (hours, minutes) => {
    let period = 'AM';
    let hour = parseInt(hours, 10);

    if (hour >= 12) period = 'PM';

    return `${hour.toString().padStart(2, '0')}:${minutes.padStart(2, '0')} ${period}`;
  };

  // Handle time change and keep dropdown open until both values are selected
  const handleTimeChange = (newHours, newMinutes) => {
    if (newHours && newMinutes) {
      const formattedTime = `${newHours.padStart(2, '0')}:${newMinutes.padStart(2, '0')}`;
      const displayTime = convertTo12HourFormat(newHours, newMinutes);
      setTime(formattedTime);
      setIsOpen(false); // Close dropdown after both hours and minutes are selected
    }
  };

  // Toggle dropdown open/close
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative flex flex-col justify-center mb-4">
      {/* Read-only input */}
      <div className='relative flex flex-col justify-center cursor-pointer' onClick={toggleDropdown}>
        <input
            required
            ref={inputRef}
            type="text"
            defaultValue={time ? convertTo12HourFormat(time.split(':')[0], time.split(':')[1]) : ''}
            onKeyDown={(e) => e.preventDefault()}
            inputMode="none"
            placeholder="Select Time"
            className={`input input-bordered w-full cursor-pointer caret-transparent ${
            !hours && !minutes ? 'text-gray-400' : ''
            }`}
        />
        <FaClock className="absolute right-5 black pointer-events-none"/>
      </div>
      
      {/* Dropdown for time selection */}
      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute top-full mt-2 left-0 w-48 p-4 bg-white border rounded-lg shadow-lg z-10"
        >
          <div className="flex justify-between space-x-2">
            {/* Hours dropdown */}
            <div className="flex flex-col items-center">
              {/* <label className="mb-2">Hours</label> */}
              <select
                value={hours}
                onChange={(e) => {
                  const newHours = e.target.value;
                  setHours(newHours);
                  handleTimeChange(newHours, minutes);
                }}
                className="select select-bordered"
              >
                <option value="" disabled>
                  HH
                </option>
                {Array.from({ length: 24 }, (_, i) => (
                  <option key={i} value={i.toString().padStart(2, '0')}>
                    {i.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>

            {/* Minutes dropdown */}
            <div className="flex flex-col items-center">
              {/* <label className="mb-2">Minutes</label> */}
              <select
                value={minutes}
                onChange={(e) => {
                  const newMinutes = e.target.value;
                  setMinutes(newMinutes);
                  handleTimeChange(hours, newMinutes);
                }}
                className="select select-bordered"
              >
                <option value="" disabled>
                  MM
                </option>
                {Array.from({ length: 60 }, (_, i) => (
                  <option key={i} value={i.toString().padStart(2, '0')}>
                    {i.toString().padStart(2, '0')}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeInput;
