import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaStar, FaRegStar } from 'react-icons/fa'; // Import star icons
import { getFirestore, doc, updateDoc, arrayUnion, arrayRemove, getDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const ActionButtons = ({ match, tripData, removeMatch }) => {    
  const [isFavorite, setIsFavorite] = useState(false); // State to track if it's a favorite
  const [loading, setLoading] = useState(true); // Loading state for button
  const { name, tripDetails } = match;
  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  // Function to check if the match is already saved in the trip's matches
  useEffect(() => {
    const checkIfFavorite = async () => {
      if (tripData) {
        const tripDocRef = doc(db, 'trips', tripData.id);
        const tripDoc = await getDoc(tripDocRef);
        if (tripDoc.exists()) {
          const savedMatches = tripDoc.data().matches || [];
          setIsFavorite(savedMatches.includes(tripDetails.id)); // Check if the trip is saved
          setLoading(false)
        }
      }
    };

    checkIfFavorite();
  }, [db, tripData, tripDetails.id]);

  // Toggle favorite status and update Firestore
  const toggleFavorite = async () => {
    if (tripData && user) {
      setLoading(true); // Set loading to true while saving
      const tripDocRef = doc(db, 'trips', tripData.id);
      const tripID = tripDetails.id;

      try {
        if (isFavorite) {
          // Remove from trip's matches
          await updateDoc(tripDocRef, {
            matches: arrayRemove(tripID),
          });
          console.log(`${tripID} removed from matches.`);
          if(removeMatch) removeMatch(tripID);
        } else {
          // Add to trip's matches
          await updateDoc(tripDocRef, {
            matches: arrayUnion(tripID),
          });
          console.log(`${name} added to matches.`);
        }

        // Update local state
        setIsFavorite(!isFavorite);
      } catch (error) {
        console.error('Error updating matches:', error);
      } finally {
        setLoading(false); // Set loading to false after saving
      }
    }
  };

  return (
    <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 p-6">
      {/* View Profile Button */}
      <div onClick={()=>{window.open(`/profile/${tripDetails.uid}`,'_blank')}}>
        <button className="btn btn-outline w-full md:w-auto">View Profile</button>
      </div>

      {/* Message Button */}
      <Link to={`/chats/user/${tripDetails.uid}`}>
        <button className="btn btn-primary w-full md:w-auto">Message {name}</button>
      </Link>

      {/* Add to Favorites Button */}
      <button
        className="btn btn-secondary w-full md:w-auto flex items-center justify-center"
        onClick={toggleFavorite}
        disabled={loading} // Disable the button while loading
      >
        {loading ? (
          <span>Loading...</span> // Show loading text while saving
        ) : isFavorite ? (
          <>
            <FaStar className="text-yellow-500 mr-1" /> Remove From Saved
          </>
        ) : (
          <>
            <FaRegStar className="text-white mr-1" /> Save Match
          </>
        )}
      </button>
    </div>
  );
};

export default ActionButtons;
