import React from 'react';
import { Link } from 'react-router-dom';
import rideImg from 'assets/images/about/hero.jpg';

const AboutUs = () => {
  return (
    <section className="py-16 px-6 lg:px-24 bg-gradient-to-b from-blue-50 to-white">
      {/* Hero Image */}
      <div className="max-w-6xl mx-auto text-center space-y-12">
        <div className="relative w-full h-80 lg:h-[500px] mb-12">
          <img src={rideImg} alt="Travelers on a road trip" className="w-full h-full object-cover rounded-lg shadow-lg" />
          <div className="absolute inset-0 bg-white opacity-20 rounded-lg"></div>
        </div>

        {/* Main Heading */}
        <h1 className="text-5xl md:text-6xl font-extrabold mb-6 text-blue-600 drop-shadow-md">
          About Ride Share
        </h1>
        <p className="text-xl text-gray-700 max-w-3xl mx-auto">
          At <strong>Ride Share</strong>, we’re passionate about making travel affordable, eco-friendly, and enjoyable. As travelers ourselves, we created this platform to connect people looking for ride-sharing solutions, whether it’s for long-distance trips or daily commutes. Ride Share is more than just a carpooling service—it’s a community where you can save money, reduce your carbon footprint, and make meaningful connections.
        </p>

        {/* Why Ride Share Section */}
        <h2 className="text-4xl font-semibold text-gray-900 mt-16">Why Ride Share?</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-left">
          <div className="p-8 bg-white shadow-xl rounded-lg hover:shadow-2xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold text-blue-600 mb-4">Save Money</h3>
            <p className="text-gray-700">
              Ride-sharing makes travel more affordable. Whether you’re embarking on a long road trip or a short ride, split the cost of gas or fare with fellow travelers and enjoy a cheaper way to reach your destination.
            </p>
          </div>
          <div className="p-8 bg-white shadow-xl rounded-lg hover:shadow-2xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold text-blue-600 mb-4">Help the Planet</h3>
            <p className="text-gray-700">
              By joining a carpool network, you’re contributing to a more sustainable future. Fewer cars on the road means reduced pollution and lower carbon emissions. Ride Share is committed to eco-friendly travel, and every ride you share helps make the planet greener.
            </p>
          </div>
          <div className="p-8 bg-white shadow-xl rounded-lg hover:shadow-2xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold text-blue-600 mb-4">Meet New People</h3>
            <p className="text-gray-700">
              Traveling is always better with company. Use Ride Share to meet like-minded travelers, swap stories, and build new friendships as you share rides to exciting destinations.
            </p>
          </div>
        </div>

        {/* How It Works Section */}
        <h2 className="text-4xl font-semibold text-gray-900 mt-16">How It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-left">
          <div className="p-8 bg-blue-50 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold text-blue-600 mb-4">Post Your Trip</h3>
            <p className="text-gray-700">
              Share your travel plans by posting your trip details. Whether you’re planning a road trip or a daily commute, Ride Share helps you find fellow travelers heading in the same direction.
            </p>
          </div>
          <div className="p-8 bg-blue-50 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold text-blue-600 mb-4">Find a Match</h3>
            <p className="text-gray-700">
              Our platform connects you with ride-sharing partners who share your route. Easily match with others for both short and long-distance rides.
            </p>
          </div>
          <div className="p-8 bg-blue-50 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold text-blue-600 mb-4">Chat & Plan</h3>
            <p className="text-gray-700">
              Once you’ve found your match, communicate with your ride partners to plan the trip details and ensure a smooth, enjoyable journey together.
            </p>
          </div>
        </div>

        {/* Join Us Section */}
        <h2 className="text-4xl font-semibold text-gray-900 mt-16">Join Us on This Journey</h2>
        <p className="text-xl text-gray-700 max-w-3xl mx-auto mb-12">
          Ride Share is not just a ride-sharing app—it’s a movement towards affordable, eco-friendly travel that connects travelers from all walks of life. Join us to save money, meet amazing people, and make your trips more meaningful.
        </p>

        {/* Call to Action Button */}
        <Link to="/start">
          <button className="btn btn-secondary btn-lg mt-10 px-8 py-3 font-bold text-white rounded-full shadow-lg hover:bg-secondary-focus hover:scale-105 transition-transform duration-300 ease-in-out">
            Join Us Now & Start Sharing Rides
          </button>
        </Link>
      </div>
    </section>
  );
};

export default AboutUs;
