import React from 'react';

const DescriptionComponent = ({ description }) => {
  return (
    <p className="text-gray-700 text-xl font-bold my-6">
    {description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ))}
  </p>
  );
}

export default DescriptionComponent;
