import React from 'react';

const Step = ({ img, title, text }) => (
  <div className="flex flex-col justify-center items-center bg-white p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300 ease-in-out">
    <div className="relative w-full h-[200px] mb-5">
      <img src={img} className="object-contain w-full h-full" alt={title} />
    </div>
    <h3 className="text-2xl font-semibold mb-4">{title}</h3>
    <p className="text-gray-600">
      {text}
    </p>
  </div>
);

export default Step;
