// TitleComponent.js

const TitleComponent = ({ tripData, ownData }) => {
    const makeTimeString = (tripData) => {
      const getDateAtUTCOffset = (timestamp, offsetInHours) => {
        const utcDate = new Date(timestamp);
        const utcHours = utcDate.getUTCHours();
        const utcMinutes = utcDate.getUTCMinutes();
        const utcSeconds = utcDate.getUTCSeconds();
        const utcYear = utcDate.getUTCFullYear();
        const utcMonth = utcDate.getUTCMonth();
        const utcDateNum = utcDate.getUTCDate();
        const adjustedHours = utcHours + offsetInHours;
        const adjustedDate = new Date(
          Date.UTC(
            utcYear,
            utcMonth,
            utcDateNum,
            adjustedHours,
            utcMinutes,
            utcSeconds
          )
        );
        return adjustedDate;
      };
      const localDate = getDateAtUTCOffset(tripData.timestamp, tripData.offsetInHours);
      const year = localDate.getUTCFullYear();
      const month = localDate.getUTCMonth() + 1;
      const day = localDate.getUTCDate();
      const hours = localDate.getUTCHours();
      const minutes = localDate.getUTCMinutes();
      const timeString = [
        year + "-",
        (month < 10 ? "0" : "") + month + "-",
        (day < 10 ? "0" : "") + day + ", ",
        (hours < 10 ? "0" : "") + hours + ":",
        (minutes < 10 ? "0" : "") + minutes + (hours < 12 ? "AM" : "PM"),
        ' (UTC' + (tripData.offsetInHours < 0 ? "" : "+") + tripData.offsetInHours + ")"
      ];
      return timeString.join('');
    };
  
    const startLocationName = tripData.startLocationName;
    const endLocationName = tripData.endLocationName;
    const timeString = makeTimeString(tripData);
  
    let ownStartLocationName, ownEndLocationName, ownTimeString;
    if (ownData) {
      ownStartLocationName = ownData.startLocationName;
      ownEndLocationName = ownData.endLocationName;
      ownTimeString = makeTimeString(ownData);
    }
  
    // Determine the color class for tripData based on the presence of ownData
    const tripDataColorClass = ownData ? "text-red-500" : "text-black";
  
    return (
      <h2 className={"text-xl font-bold "+(ownData?"":" mb-8 text-center")}>
        <span className={tripDataColorClass}>
          {startLocationName} → {endLocationName}
        </span>
        <br />
        <span className={tripDataColorClass}>
            {timeString}
        </span>
        {ownData && (
          <>
            <br />
            <span className="text-blue-500">
                {ownStartLocationName} → {ownEndLocationName}<br/>
            </span>
            <span className="text-blue-500">
                {ownTimeString}
            </span>
          </>
        )}
      </h2>
    );
};
  
export default TitleComponent;
  