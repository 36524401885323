import React from 'react';
import HeroSection from './components/HeroSection';
import TextSection from './components/TextSection';
import HowItWorks from './components/HowItWorks';
import PlanetSection from './components/PlanetSection';
import AboutUsSection from './components/AboutUsSection';
import BlogSection from './components/BlogSection';
import CTARide from './components/CTARide';
import Loader from 'components/Loader';
import logo from 'assets/images/logo.png';

const Land = ({ ssr }) => {
  return (
    <div className="bg-gray-50">
      {ssr && (
        <div className='fixed z-50 flex flex-col justify-center items-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <div className='relative z-20'>
            <img src={logo} alt="Logo" className='m-5' style={{ width: "300px", height: "auto" }} />
            <Loader margin="50" />
          </div>
          <div className='absolute w-[150%] h-[150%] bg-white blur-2xl rounded-full opacity-70' />
        </div>
      )}
      <HeroSection />
      <TextSection/>
      <HowItWorks />
      <PlanetSection />
      <div className='flex flex-col md:flex-row'>
        <AboutUsSection />
        <BlogSection />
      </div>
      <CTARide />
    </div>
  );
};

export default Land;
