import React from 'react';

const CookiePolicy = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto bg-base-100 shadow-lg rounded-lg m-5">
      <h1 className="text-4xl font-bold mb-4">Cookie Policy</h1>

      <p className="mb-4">
        At <strong>Ride Share</strong>, we value your privacy and strive to provide a safe and personalized experience. This Cookie Policy explains what cookies are, how we use them, and your options regarding their use.
      </p>

      <h2 className="text-2xl font-semibold mb-2">1. What Are Cookies?</h2>
      <p className="mb-4">
        Cookies are small text files stored on your device when you visit websites. They allow us to recognize your device and gather information about how you interact with our website. Cookies may be "persistent" (remaining on your device until deleted) or "session" cookies (deleted once you close your browser).
      </p>

      <h2 className="text-2xl font-semibold mb-2">2. Types of Cookies We Use</h2>
      <p className="mb-4">We use several types of cookies to improve your experience, including:</p>
      <ul className="list-disc list-inside mb-4">
        <li><strong>Essential Cookies</strong>: Necessary for our website to function. They enable core features such as security and account management.</li>
        <li><strong>Performance Cookies</strong>: These cookies help us understand how users interact with our site by collecting anonymous usage data. We use this information to improve our website's performance.</li>
        <li><strong>Functionality Cookies</strong>: These cookies remember your preferences, such as language and location, to provide a more personalized experience.</li>
        <li><strong>Advertising Cookies</strong>: These cookies track your online activity to help us serve relevant ads. They may be set by us or third parties.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">3. Third-Party Cookies</h2>
      <p className="mb-4">
        Some cookies are placed by third-party services that we use, such as analytics tools (e.g., Google Analytics) or advertising platforms. These third parties may use cookies to track your activity across websites.
      </p>

      <h2 className="text-2xl font-semibold mb-2">4. Your Choices Regarding Cookies</h2>
      <p className="mb-4">
        You can control and manage cookies through your browser settings. Most browsers allow you to block or delete cookies. However, please note that disabling cookies may affect your ability to use certain features of our website.
      </p>

      <h2 className="text-2xl font-semibold mb-2">5. Updates to This Policy</h2>
      <p className="mb-4">
        We may update this Cookie Policy from time to time. Any changes will be posted on this page, and we will update the "Effective Date" at the top of the policy.
      </p>

      <h2 className="text-2xl font-semibold mb-2">6. Contact Us</h2>
      <p className="mb-4">
        If you have any questions about this Cookie Policy, feel free to contact us at <a href="mailto:contact@ride-share.app" className="link link-primary">contact@ride-share.app</a>.
      </p>
    </div>
  );
};

export default CookiePolicy;
