import React, { useState, useEffect } from 'react';
import Config from "../../../assets/Config";
import { getFunctions, httpsCallable } from 'firebase/functions';

const ForgotPassword = ({ initialEmail, change }) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const functions = getFunctions();

  useEffect(() => {
    if (initialEmail) {
      setEmail(initialEmail); // Set the initial email when the overlay is opened
    }
  }, [initialEmail]);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setMessage('');

    try {
      const sendPasswordResetLink = httpsCallable(functions, 'sendPasswordResetLink');
      const result = await sendPasswordResetLink({ email });

      setMessage('Password reset email sent! Check your inbox.');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenOverlay = () => setShowOverlay(true);
  const handleCloseOverlay = () => setShowOverlay(false);

  return (
    <div className="text-center mt-4">
      <a href="#" onClick={handleOpenOverlay} className="text-blue-500 hover:underline m-0">
        {change?"Change Password":"Forgot your password?"}
      </a>

      {/* Overlay */}
      {showOverlay && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
            <h2 className="text-xl font-semibold mb-4">Reset your password</h2>
            {message && <p className="text-green-500">{message}</p>}
            {error && <p className="text-red-500">{error}</p>}
            <form onSubmit={handleForgotPassword}>
              {!change && <div className="mb-4">
                <label htmlFor="reset-email" className="block text-sm font-medium text-gray-700 mb-2">
                  Enter your email
                </label>
                <input
                  type="email"
                  id="reset-email"
                  className="input input-bordered w-full"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={loading}
                />
              </div>}
              <div className="flex justify-between">
                <button type="button" onClick={handleCloseOverlay} className="btn btn-outline">
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? 'Sending...' : 'Send reset email'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
