import React from 'react';

const EmailField = ({ email, value, onChange, disabled,placeholder }) => {
  return (
    <div className="mb-4">
      <input
        type="email"
        name={email}
        id={email}
        value={value}
        onChange={onChange}
        className="input input-bordered w-full"
        placeholder={placeholder || 'Email'}
        required
        disabled={disabled} // Disable input when signing in
      />
    </div>
  );
};

export default EmailField;
