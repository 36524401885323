import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Title and Intro Section */}
        <h1 className="text-5xl font-extrabold text-blue-600 text-center">
          How to Find Travel Companions for Long-Distance Rides
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Planning a long-distance trip can be exciting, but solo travel can be costly and lonely. Here’s how ride-sharing can help you find the perfect travel companions for your next journey.
        </p>

        {/* Tip 1: Use a Dedicated Ride-Sharing Platform */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            1. Use a Dedicated Ride-Sharing Platform
          </h2>
          <p className="text-lg text-gray-700">
            One of the best ways to find travel companions for <strong>long-distance rides</strong> is through a dedicated platform like Ride Share. Unlike general travel apps, Ride Share focuses on connecting travelers for <strong>long-distance trips</strong>, ensuring you match with people heading the same way.
          </p>
        </section>

        {/* Tip 2: Post Your Trip Early and Be Specific */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            2. Post Your Trip Early and Be Specific
          </h2>
          <p className="text-lg text-gray-700">
            Posting your trip details early gives potential companions more time to find and join your ride. Be specific about key details, such as:
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Departure and destination points</strong></li>
            <li><strong>Travel dates and times</strong></li>
            <li><strong>Preferred stops</strong> along the way</li>
          </ul>
          <p className="text-lg text-gray-700">
            Being upfront about your plans increases the chances of finding a travel companion who shares your preferences.
          </p>
        </section>

        {/* Tip 3: Check Profiles and Reviews */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            3. Check Profiles and Reviews of Potential Companions
          </h2>
          <p className="text-lg text-gray-700">
            Before agreeing to share a ride, check the profiles and reviews of potential companions. Look for:
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Verified profiles</strong> for reliability</li>
            <li><strong>Positive reviews</strong> from other users</li>
            <li><strong>Travel preferences</strong> that align with yours</li>
          </ul>
          <p className="text-lg text-gray-700">
            Doing your due diligence helps ensure a smoother, more enjoyable trip.
          </p>
        </section>

        {/* Tip 4: Communicate Clearly Before the Trip */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            4. Communicate Clearly Before the Trip
          </h2>
          <p className="text-lg text-gray-700">
            Good communication is key to a successful ride. Make sure to confirm:
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Cost-sharing arrangements</strong> for fuel, tolls, and other expenses</li>
            <li><strong>Meeting points and times</strong> to avoid confusion</li>
            <li><strong>Baggage limits</strong> to ensure there's enough space</li>
          </ul>
          <p className="text-lg text-gray-700">
            Clear communication sets the right expectations and ensures a smoother experience for everyone involved.
          </p>
        </section>

        {/* Tip 5: Plan for Flexibility and Enjoy the Journey */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            5. Plan for Flexibility and Enjoy the Journey
          </h2>
          <p className="text-lg text-gray-700">
            While planning is important, staying open to a bit of spontaneity can make your trip even better. If your companion suggests a scenic detour or fun stop, embrace the opportunity and enjoy the journey.
          </p>
          <p className="text-lg text-gray-700">
            After all, shared rides are about more than just getting to your destination—they’re about making the journey memorable.
          </p>
        </section>

        {/* Conclusion Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Conclusion
          </h2>
          <p className="text-lg text-gray-700">
            Finding the right <strong>travel companions for long-distance rides</strong> can transform a road trip into a fun, social, and affordable experience. By using a platform like Ride Share, posting your trip early, reviewing profiles, communicating clearly, and staying flexible, you’ll be on your way to a memorable journey.
          </p>
          <p className="text-lg text-gray-700">
            Ready to find your next travel companion? <Link to="/register" className="text-blue-500 font-bold">Join Ride Share</Link> today and connect with travelers for your next long-distance adventure!
          </p>
        </section>

        {/* Call to Action */}
        <div className="text-center py-8">
          <Link to="/register">
            <button className="btn btn-primary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
              Join Ride Share Now!
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
