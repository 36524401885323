import React, { useState, useEffect } from 'react';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { useSearchParams } from 'react-router-dom'; // Added useNavigate

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const [oobCode, setOobCode] = useState('');
  const [verified, setVerified] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const code = searchParams.get('oobCode');
    if (code) {
      verifyResetCode(code);
    } else {
      setError('Invalid reset link');
    }
  }, [searchParams]);

  const verifyResetCode = async (code) => {
    const auth = getAuth();
    try {
      await verifyPasswordResetCode(auth, code);
      setOobCode(code);
      setVerified(true);
    } catch (error) {
      setError('The reset code is invalid or expired.');
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!verified) {
      setError('Password reset link is not verified.');
      setLoading(false);
      return;
    }
    const auth = getAuth();
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setSuccessMessage('Your password has been reset successfully.');
      setNewPassword('');
    } catch (error) {
      setError('Failed to reset the password. Try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleGoToLogin = () => {
    window.open('/login', '_self')
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-md w-[90%] max-w-md">
        <h2 className="text-xl font-semibold mb-4">Reset Your Password</h2>
        {error ? <p className="text-red-500 mb-4">{error}</p>:
        successMessage ? (
          <div>
            <p className="text-green-500 mb-4">{successMessage}</p>
            <button
              onClick={handleGoToLogin}
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors"
            >
              Go to Login
            </button>
          </div>
        ) : verified ? (
          <form onSubmit={handlePasswordChange} className="space-y-4">
            <div>
              <h3 className="text-base font-semibold mb-2">New Password</h3>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
                className="input input-bordered w-full"
                disabled={loading}
                required
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="submit"
                className={`bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors ${loading ? 'opacity-50' : ''}`}
                disabled={loading}
              >
                {loading ? 'Resetting...' : 'Reset Password'}
              </button>
            </div>
          </form>
        ) : (
          <p>Verifying your password reset link...</p>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
