import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore'; // Firestore imports
import { getAuth } from 'firebase/auth'; // Firebase Auth import
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import TripItem from './components/TripItem'; // Import TripItem component

const MyTrips = () => {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTrips = async () => {
      setLoading(true);
      try {
        const db = getFirestore(); // Initialize Firestore
        const auth = getAuth(); // Get Auth instance
        const user = auth.currentUser;

        if (user) {
          const tripsCollection = collection(db, 'trips'); // Reference to the 'trips' collection
          const q = query(tripsCollection, where('uid', '==', user.uid)); // Query trips where 'uid' matches the logged-in user's UID
          const querySnapshot = await getDocs(q); // Fetch the trips

          const tripsData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          })); // Map through the results to extract trip data
          
          setTrips(tripsData); // Set fetched trips in state
        } else {
          console.error('No user is logged in');
        }
      } catch (error) {
        console.error('Error fetching trips:', error);
      } finally {
        setLoading(false); // Set loading to false when data is fetched
      }
    };

    fetchTrips(); // Call the function to fetch trips on component mount
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="min-h-screen p-4 md:p-8 flex flex-col items-center space-y-4">
      {/* Container is responsive and centered with mobile adjustments */}
      <Link to={`/create-trip`} >
            <button className="btn btn-primary">New Trip</button>
    </Link>
      <div className="max-w-full md:max-w-4xl mx-auto bg-white p-4 md:p-6 rounded-lg shadow-md">
        {/* Trip List */}
        <ul>
          {trips.length > 0 ? (
            trips.map((trip) => (
              <TripItem key={trip.id} tripData={trip} />
            ))
          ) : (
            <p className='text-center'>
                You don't have any trips planned yet.<br/>
                <Link to="/create-trip">
                    <button className="btn btn-primary px-6 py-3 font-semibold rounded-lg mt-4">
                        Plan a Trip & Share the Ride!
                    </button>
                </Link>
            </p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default MyTrips;
