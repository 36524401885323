import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Title and Hero Section */}
        <h1 className="text-5xl font-extrabold text-blue-600 text-center">
          The Ultimate Guide to Ride Sharing for Travelers
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Ride Share is a cost-effective, eco-friendly, and social way to travel, whether you're planning a long-distance road trip or just getting around town. In this guide, we’ll walk you through everything you need to know about ride sharing and how it can transform your travel experiences.
        </p>

        {/* Introduction */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            What Is Ride Sharing?
          </h2>
          <p className="text-lg text-gray-700">
            Ride sharing is a simple concept: people heading in the same direction share a vehicle, whether it’s for a short trip or a long-distance journey. With Ride Share, you can connect with fellow travelers to share the cost of your trip while helping the environment.
          </p>
          <p className="text-lg text-gray-700">
            Whether you're looking to save money, reduce your carbon footprint, or meet new people, Ride Share helps make every trip more meaningful.
          </p>
        </section>

        {/* How It Works Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            How Does Ride Share Work?
          </h2>
          <p className="text-lg text-gray-700">
            Here’s a step-by-step guide on how to get started:
          </p>
          <ol className="list-decimal list-inside space-y-4">
            <li>
              <strong>Post Your Trip:</strong> Enter trip details like your starting point, destination, date, and time.
            </li>
            <li>
              <strong>Find a Match:</strong> Browse a list of potential travel companions heading in the same direction.
            </li>
            <li>
              <strong>Connect & Plan:</strong> Use Ride Share’s messaging system to coordinate with your ride partners.
            </li>
            <li>
              <strong>Enjoy the Ride:</strong> Meet your travel partner, share stories, and enjoy the journey together.
            </li>
          </ol>
        </section>

        {/* Benefits Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Why Choose Ride Sharing?
          </h2>
          <p className="text-lg text-gray-700">
            Ride sharing is much more than just a way to save money. Here are the key reasons why travelers love Ride Share:
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li>
              <strong>Affordable Travel:</strong> Share the cost of fuel or fares with your travel companions, making travel more budget-friendly.
            </li>
            <li>
              <strong>Eco-Friendly:</strong> Ride sharing helps reduce the number of cars on the road, cutting down on pollution and traffic congestion.
            </li>
            <li>
              <strong>Meet New People:</strong> Travel with like-minded people, make new friends, and share memorable experiences.
            </li>
            <li>
              <strong>Flexibility & Convenience:</strong> Choose when and where to travel, unlike rigid public transportation schedules.
            </li>
          </ul>
        </section>

        {/* Tips Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Tips for a Successful Ride Share Experience
          </h2>
          <ul className="list-disc list-inside space-y-4">
            <li>
              <strong>Communicate Clearly:</strong> Ensure you and your travel partners agree on meeting points and travel preferences.
            </li>
            <li>
              <strong>Check Profiles & Reviews:</strong> Read user profiles and reviews to find the best match for your travel style.
            </li>
            <li>
              <strong>Plan Ahead:</strong> Post your trip early to give others time to join and plan accordingly.
            </li>
            <li>
              <strong>Stay Safe:</strong> Always choose well-reviewed travel partners and trust your instincts.
            </li>
          </ul>
        </section>

        {/* Who Can Use Ride Share Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Who Can Use Ride Share?
          </h2>
          <p className="text-lg text-gray-700">
            Ride Share is perfect for:
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li>
              <strong>Solo Travelers:</strong> Find companions for your next road trip and travel with peace of mind.
            </li>
            <li>
              <strong>Budget Travelers:</strong> Ride sharing helps you cut down on travel expenses, making it more affordable for everyone.
            </li>
            <li>
              <strong>Eco-Conscious Travelers:</strong> Reduce your carbon footprint by carpooling with others.
            </li>
          </ul>
        </section>

        {/* Conclusion Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Conclusion
          </h2>
          <p className="text-lg text-gray-700">
            Whether you're planning a road trip or just need a ride across town, Ride Share offers a cost-effective, eco-friendly, and social way to travel. By ride sharing, you save money, reduce your environmental impact, and make new connections.
          </p>
          <p className="text-lg text-gray-700">
            Ready to get started? <Link to="/register" className="text-blue-500 font-bold">Sign up today</Link> and join our growing community of travelers!
          </p>
        </section>

        {/* Call to Action */}
        <div className="text-center py-8">
          <Link to="/register">
            <button className="btn btn-primary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
              Join Ride Share Now!
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
