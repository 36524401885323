import { useState } from 'react';
import Login from './components/Login';
import Register from './components/Register';

const Auth = () => {
  const [isLogin, setIsLogin] = useState(false); // State to toggle between Login and Register

  return (
    <div className="p-[30px]">
      {isLogin ? <Login /> : <Register />}
      
      <p className="mt-4 text-center">
        {isLogin ? (
          <>
            Don't have an account?{' '}
            <button 
              className="text-blue-500 underline"
              onClick={() => setIsLogin(false)}
            >
              Register
            </button>
          </>
        ) : (
          <>
            Already have an account?{' '}
            <button 
              className="text-blue-500 underline"
              onClick={() => setIsLogin(true)}
            >
              Login
            </button>
          </>
        )}
      </p>
    </div>
  );
};

export default Auth;
