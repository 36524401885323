import React, { forwardRef } from 'react';

const handleChange = (e)=>{
    if(e.target.value=='Unknown User') e.target.value = 'Unknown Use';
}

const InputField = forwardRef(({ label, type, name, defaultValue, disabled, required = false }, ref) => {  
  return (
    <div>
      <label className="block font-semibold mb-1">{label}</label>
      <input
        type={type}
        name={name}
        ref={ref}
        defaultValue={defaultValue}
        required={required}
        onChange={handleChange}
        className="w-full border border-gray-300 rounded-md p-2"
        disabled={disabled}
      />
    </div>
  );
});

export default InputField;
