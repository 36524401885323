import React from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import googleImg from 'assets/images/auth/google.svg';

const GoogleSignIn = ({setLoading, register, setError}) => {
  const handleGoogleSignIn = async () => {
    if(setError) setError(null);
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    setLoading(true);
    try {
      await signInWithPopup(auth, provider);
      console.log('User signed in with Google');
    } catch (error) {
      console.error(error);
      if(setError) setError(error.message)
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Divider */}
      <div className="flex items-center justify-center my-4">
        <hr className="w-full border-t border-gray-300" />
        <span className="mx-4 text-gray-500">OR</span>
        <hr className="w-full border-t border-gray-300" />
      </div>

      {/* Google Sign In Button */}
        <button className="flex flex-row btn btn-outline w-full" onClick={handleGoogleSignIn}>
            <img src={googleImg} className='h-[20px]'/>
            {register?"Register":"Sign in"} with Google
        </button> 
    </div>
  );
};

export default GoogleSignIn;
