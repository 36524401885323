import React, { useEffect, useRef, useState } from 'react';
import { getFirestore, collection, query, orderBy, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import MessageItem from './MessageItem';
import Loader from '../../../components/Loader.js'; // Assuming the Loader component is in the same directory

const MessageList = ({ currentUserId, partnerName, currentChat }) => { 
       
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state for messages
  const db = getFirestore();
  const messageListRef = useRef(null);

  // Scroll to the bottom of the message list
  const scrollToBottom = () => {
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  };

  // Function to update the lastReadMessageId for the current user in Firestore
  const updateLastReadMessageId = async (chatId, userId, lastMessageId) => {
    const chatRef = doc(db, 'chats', chatId);
    await updateDoc(chatRef, {
      [`lastReadMessageId.${userId}`]: lastMessageId,
    });
  };

  // Subscribe to messages in the current chat
  useEffect(() => {
    if (!currentChat?.id) return;

    const messagesRef = collection(db, 'chats', currentChat.id, 'messages');
    const messagesQuery = query(messagesRef, orderBy('timestamp', 'asc'));

    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
        const messagesData = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
          // Filter out messages with the `init: true` attribute
          .filter(message => !message.init);
      
        setMessages(messagesData);
        setLoading(false); // Set loading to false once messages are fetched
      });

    return () => unsubscribe(); // Cleanup on unmount
  }, [currentChat?.id, db]);

  // Update last read message when the messages change
  useEffect(() => {
    if (messages.length > 0) {
      const latestMessageId = messages[messages.length - 1]?.id;
      if (currentChat?.id && latestMessageId) {
        updateLastReadMessageId(currentChat.id, currentUserId, latestMessageId);
      }
      scrollToBottom();
    }
  }, [messages, currentChat?.id, currentUserId]);

  return (
    <div ref={messageListRef} className="flex-1 overflow-y-auto p-4">
      {loading ? (
        <Loader />  // Display loading spinner while fetching messages
      ) : messages.length > 0 ? (
        <ul className="space-y-4">
          {messages.map((message, index) => (
            <MessageItem
              key={index}
              message={message}
              currentUserId={currentUserId}
              partnerName={partnerName}
            />
          ))}
        </ul>
      ) : (
        <p>No messages yet. Start the conversation!</p>
      )}
    </div>
  );
};

export default MessageList;
