import React, { useState } from 'react';
import { getFirestore, collection, addDoc, doc, updateDoc, setDoc } from 'firebase/firestore';

const MessageInput = ({ currentChat, currentUserId, partnerUID }) => {
  const [message, setMessage] = useState('');
  const db = getFirestore();
  const handleSendMessage = async (messageText) => {
    const newMessage = {
        senderId: currentUserId,
        text: messageText,
        timestamp: new Date(),
      };

      const messagesRef = collection(db, 'chats', currentChat.id, 'messages');
      const messageDoc = await addDoc(messagesRef, newMessage);

      // Update the chat document with latest message ID and text
      const chatRef = doc(db, 'chats', currentChat.id);
      await updateDoc(chatRef, {
        latestMessageId: messageDoc.id,
        latestMessageText: messageText,
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      handleSendMessage(message);
      setMessage(''); // Clear input after sending
    }
  };

  return (
    <div className="p-4 border-t border-gray-300 flex-shrink-0">
      <form onSubmit={handleSubmit} className="flex">
        <input
          type="text"
          className="flex-1 p-3 border rounded-lg"
          placeholder="Type a message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <button
          type="submit"
          className="btn btn-primary ml-4"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default MessageInput;
