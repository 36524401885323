import React from 'react';
import { Link } from 'react-router-dom';

const TermsCont = () => {
  return (
    <div>
      <h1 className="text-4xl font-bold mb-4">Terms of Use</h1>
      
      <p className="mb-4">
        Welcome to <strong>Ride Share</strong> ("we," "our," or "us"), a ride-sharing platform designed to help users post trips, find matches, and communicate with each other. 
        By using the <strong>Ride Share</strong> website, mobile app, or any other services (collectively, the "Service"), you agree to these Terms of Use ("Terms"). 
        Please read them carefully.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">1. Acceptance of Terms</h2>
      <p className="mb-4">
        By accessing or using the Service, you agree to be bound by these Terms, our Privacy Policy, and any additional terms and conditions that apply to certain aspects of the Service. 
        If you do not agree, you must stop using the Service immediately.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">2. Eligibility</h2>
      <p className="mb-4">
        You must be at least 18 years old to use the Service. By using the Service, you represent that you are of legal age and capable of entering into a legally binding agreement.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">3. Account Registration</h2>
      <p className="mb-4">
        To use the Service, you may be required to register for an account. You agree to provide accurate and complete information during the registration process. 
        You are responsible for maintaining the confidentiality of your account and password and are responsible for all activities that occur under your account.
      </p>
      <p className="mb-4">
        We reserve the right to suspend or terminate your account at any time if we believe that you have violated these Terms.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">4. User Conduct</h2>
      <p className="mb-4">
        You agree to use the Service for lawful purposes only and in accordance with these Terms. You agree not to:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>Impersonate any person or entity or falsely represent your affiliation with any person or entity.</li>
        <li>Post, transmit, or otherwise make available any content that is harmful, abusive, defamatory, or otherwise objectionable.</li>
        <li>Use the Service for any commercial purposes without our prior written consent.</li>
        <li>Engage in any activity that could interfere with the proper functioning of the Service.</li>
      </ul>
      
      <h2 className="text-2xl font-semibold mb-2">5. Posting and Matching Trips</h2>
      <p className="mb-4">
        Users may post trips and find matches through the Service. When posting a trip, you must provide accurate information about your planned trip, 
        including but not limited to start and end locations, times, and any other relevant details.
      </p>
      <p className="mb-4">
        You are solely responsible for any interactions you have with other users, including coordinating the trip, setting expectations, and determining any compensation or other arrangements. 
        <strong>Ride Share</strong> does not provide any insurance, protection, or guarantees for users or trips arranged through the Service. 
        You acknowledge and agree that all actions taken within the app or as a consequence of using the app are your sole responsibility.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">6. Communication with Other Users</h2>
      <p className="mb-4">
        The Service allows users to communicate with each other via an internal messaging system. While we provide this tool, we do not monitor or moderate messages. 
        You are responsible for your interactions with other users. If you experience any abusive or inappropriate behavior, you may report it to us, and we may take appropriate action at our discretion.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">7. Public Profile Information</h2>
      <p className="mb-4">
        Your profile, including your posted trips, is publicly visible to other users. However, certain personal information, such as your email address, will not be made public. 
        You are responsible for maintaining the accuracy of the information on your profile.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">8. Reviews and Ratings</h2>
      <p className="mb-4">
        Users may leave reviews and ratings about their ride-sharing experience. Reviews must be fair, accurate, and not violate any applicable laws. 
        We reserve the right to remove any reviews we deem inappropriate or harmful.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">9. Privacy</h2>
      <p className="mb-4">
        Your privacy is important to us. Please refer to our <Link to="/privacy-policy" className="link link-primary">Privacy Policy</Link> for details on how we collect, use, and protect your personal information.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">10. No Insurance or Liability for Actions</h2>
      <p className="mb-4">
        You acknowledge and agree that <strong>Ride Share</strong> does not provide any insurance or compensation for accidents, injuries, or other incidents that may occur during or as a result of trips arranged through the Service. 
        You are fully responsible for your own safety and any actions taken as a consequence of using the Service.
      </p>
      <p className="mb-4">
        We make no warranties, either express or implied, regarding the safety or reliability of users or their vehicles. You understand that you participate in ride-sharing at your own risk, and <strong>Ride Share</strong> will not be held liable for any loss, damages, or injuries that may occur.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">11. Limitation of Liability</h2>
      <p className="mb-4">
        The Service is provided on an "as-is" and "as-available" basis. We make no warranties, either express or implied, regarding the Service or your use of the Service. 
        To the fullest extent permitted by law, we disclaim any and all liability for any direct, indirect, incidental, consequential, or punitive damages arising out of your use or inability to use the Service.
      </p>
      <p className="mb-4">
        We do not verify the identity of users or the accuracy of the trips they post. You agree that any travel arrangements made through the Service are at your own risk, 
        and we are not responsible for any harm or damages that may result from such arrangements.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">12. Termination</h2>
      <p className="mb-4">
        We reserve the right to terminate or suspend your access to the Service at our sole discretion, without notice, for conduct that we believe violates these Terms or is otherwise harmful to us or other users.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">13. Modifications to the Terms</h2>
      <p className="mb-4">
        We may modify these Terms at any time. If we make material changes, we will notify you by posting the updated Terms on our website or through other communication methods. 
        Your continued use of the Service after such changes constitutes acceptance of the new Terms.
      </p>
      
      <h2 className="text-2xl font-semibold mb-2">14. Contact Us</h2>
      <p className="mb-4">
        If you have any questions about these Terms or the Service, please contact us at <a href="mailto:contact@ride-share.app" className="link link-primary">contact@ride-share.app</a>.
      </p>
    </div>
  );
}

export default TermsCont;
