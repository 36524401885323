import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Title and Intro Section */}
        <h1 className="text-5xl font-extrabold text-blue-600 text-center">
          Ride Sharing vs. Car Rentals: Which is Better for Travelers?
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Planning your next trip and unsure whether to choose ride sharing or rent a car? In this post, we’ll compare the cost, convenience, and environmental impact of both options to help you decide which is better for your travels.
        </p>

        {/* Section 1: Cost Efficiency */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            1. Cost Efficiency: Which Option Saves You More Money?
          </h2>
          <p className="text-lg text-gray-700">
            When it comes to affordability, <strong>ride sharing</strong> is often the more economical choice for short trips, while <strong>car rentals</strong> may be better for long-distance or remote travel.
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Ride Sharing</strong>: Pay per ride with no upfront costs, and split fares when carpooling.</li>
            <li><strong>Car Rentals</strong>: Includes daily or weekly rates, fuel charges, and potential parking fees.</li>
          </ul>
          <p className="text-lg text-gray-700">
            <strong>Winner</strong>: For short trips and local commutes, ride sharing is more cost-efficient. For long-distance trips, car rentals can sometimes be more practical.
          </p>
        </section>

        {/* Section 2: Convenience */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            2. Convenience: Flexibility vs. Hassle-Free Travel
          </h2>
          <p className="text-lg text-gray-700">
            Ride sharing offers hassle-free travel where you don’t need to worry about driving, parking, or maintaining a vehicle. In contrast, car rentals provide total control, especially for road trips.
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Ride Sharing</strong>: Easy to book via app, no need to drive or find parking.</li>
            <li><strong>Car Rentals</strong>: Full control over your schedule and routes, ideal for multiple stops and road trips.</li>
          </ul>
          <p className="text-lg text-gray-700">
            <strong>Winner</strong>: Ride sharing is more convenient for short, urban trips. Car rentals offer more flexibility for long-distance travel.
          </p>
        </section>

        {/* Section 3: Environmental Impact */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            3. Environmental Impact: Which is More Eco-Friendly?
          </h2>
          <p className="text-lg text-gray-700">
            When it comes to sustainability, <strong>ride sharing</strong> is generally the more eco-friendly option, especially when carpooling or using electric vehicles.
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Ride Sharing</strong>: Reduces carbon emissions by sharing rides, with some services offering electric vehicle options.</li>
            <li><strong>Car Rentals</strong>: The environmental impact depends on the type of vehicle. Fuel-efficient and hybrid cars are better for the environment than gas-guzzling models.</li>
          </ul>
          <p className="text-lg text-gray-700">
            <strong>Winner</strong>: Ride sharing is typically more eco-friendly, especially when carpooling or using electric vehicles.
          </p>
        </section>

        {/* Section 4: Availability */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            4. Availability: Which Option is Easier to Access?
          </h2>
          <p className="text-lg text-gray-700">
            Ride-sharing services are widely available in most cities, while car rentals can be easier to find in remote or rural areas where ride sharing may be limited.
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Ride Sharing</strong>: Widely available in urban areas but limited in rural regions.</li>
            <li><strong>Car Rentals</strong>: Available in most urban and rural areas, especially near airports and major hubs.</li>
          </ul>
          <p className="text-lg text-gray-700">
            <strong>Winner</strong>: Ride sharing is easier in urban settings, while car rentals are more reliable in rural or remote locations.
          </p>
        </section>

        {/* Conclusion Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Conclusion
          </h2>
          <p className="text-lg text-gray-700">
            Both <strong>ride sharing</strong> and <strong>car rentals</strong> offer unique benefits depending on your travel needs. For short trips and eco-conscious travelers, <strong>ride sharing</strong> is typically the best option. For longer journeys or trips to rural areas, <strong>car rentals</strong> may offer more convenience and flexibility.
          </p>
          <p className="text-lg text-gray-700">
            Ready to share your next ride? <Link to="/start" className="text-blue-500 font-bold">Join Ride Share</Link> today and explore a cost-effective, eco-friendly way to travel!
          </p>
        </section>

      </div>
    </div>
  );
};

export default BlogPost;
