import React, { useEffect, useRef, useState } from 'react';
import RadiusInput from './RadiusInput'

var searchFlag;
const LocationInput = ({ label, locationName, setLocationName, setCoords, coords, radius, setRadius }) => {
  const autocompleteRef = useRef(null);
  const [selected, setSelected] = useState();

  // Reverse geocode to get the location name from coordinates
  const getPlaceName = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat, lng };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        const place = results[0];        
        let locName = place.formatted_address;
        if(place['address_components'].length>1){
            const adrsList = place['address_components'];            
            const country = adrsList.find(item => item.types.includes("country"));            
            const area = adrsList.find(item => item.types.includes("administrative_area_level_1"));
            const name = adrsList.find(item => {
                if (item.types.includes("locality")) {
                  return true;
                } else if (item.types.includes("route")) {
                  return true;
                }
                else if (item.types.includes("administrative_area_level_4")) {
                    return true;
                }
                else if (item.types.includes("administrative_area_level_3")) {
                    return true;
                }
                else if (item.types.includes("administrative_area_level_2")) {
                    return true;
                }
                return false;
            })
            const arr = [name && name['long_name'], area && area['long_name'], country && country['long_name']];
            locName = arr.join(', ');
        }
        setLocationName(locName); // Set the name of the location
        setSelected(true);
      } else {
        console.error('Geocode was not successful for the following reason:', status);
      }
    });
  };
  const handleChange = (e)=>{
    setLocationName(e.target.value)
    setSelected(false)
  }
  const handleBlur = (e)=>{
    if(!selected){
        e.target.value = '';
        setLocationName('');
        setCoords();
    }
  }
  // Initialize Google Places Autocomplete
  useEffect(() => {
    if (window.google) {
      const google = window.google;
      const autocomplete = new google.maps.places.Autocomplete(autocompleteRef.current);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) return;
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        searchFlag = true;
        
        // set name
        const name = place.name;
        const country = place['address_components'].pop()['long_name'];
        const area = place['address_components'].pop()['long_name'];
        const locName = [name, area, country]
        setLocationName(locName.join(', '));
        setCoords({ lat, lng });
        setSelected(true);
      });
    }
  }, []);

  // When the coordinates are updated from the map, trigger reverse geocoding
  useEffect(() => {
    if (coords && coords.lat && coords.lng) {
        if(searchFlag) searchFlag = false;
        else getPlaceName(coords.lat, coords.lng);
    }
  }, [coords]);

  return (
    <div className="mb-4 flex flex-row w-full relative">
        <input
            ref={autocompleteRef}
            type="text"
            name={label}
            id={label}
            className="input input-bordered w-[70%]"
            placeholder={label}
            value={locationName}
            onBlur={handleBlur}
            onChange={handleChange} // Allow manual input
            required
        />
        <div className='m-2'/>
        <RadiusInput radius={radius} setRadius={setRadius}/>
    </div>
  );
};

export default LocationInput;
