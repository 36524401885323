import React from 'react';
import { Link } from 'react-router-dom';

const AboutUsSection = () => (
  <div className="bg-gray-100 py-24">
    <div className="max-w-7xl mx-auto px-4 text-center">
      <h2 className="text-4xl font-bold mb-6">Learn More About Us</h2>
      <p className="text-lg text-gray-700 mb-4">
        We’re passionate travelers who wanted to make it easier for people to connect and share rides. Learn more about our story, our team, and our mission to create a greener, more connected world.
      </p>
      <Link to="/about">
        <button className="btn btn-secondary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
          Read Our Story
        </button>
      </Link>
    </div>
  </div>
);

export default AboutUsSection;
