import React, { useState } from 'react';
import { getAuth, signOut } from 'firebase/auth';

const Logout = () => {
    const [showConfirm, setShowConfirm] = useState(false);

    const handleLogout = () => {
        signOut(getAuth());
    };

    const toggleConfirm = () => {
        setShowConfirm(!showConfirm);
    };

    return (
        <div>
            <button className="btn btn-error" onClick={toggleConfirm}>
                Logout
            </button>
            
            {showConfirm && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h2 className="text-lg font-bold">Are you sure you want to log out?</h2>
                        <div className="mt-4 flex justify-between">
                            <button className="btn btn-secondary mr-2" onClick={toggleConfirm}>
                                Cancel
                            </button>
                            <button className="btn btn-error" onClick={handleLogout}>
                                Confirm Logout
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Logout;
