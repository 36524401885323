import React, { useState, useEffect, useRef } from 'react';
import { FaCalendar } from 'react-icons/fa';
import CalendarPicker from './CalendarPicker';

// Helper function to format the date as YYYY-MM-DD
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 because months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const DatePicker = ({ date, setDate }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  // Handle closing the dropdown when clicking outside
  const closeDropdown = (e) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target) &&
      !inputRef.current.contains(e.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => document.removeEventListener('click', closeDropdown);
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closePicker = () => {
    setIsOpen(false); // Close the picker on date selection
  };

  // Update date when selectedDate changes
  useEffect(() => {
    if (selectedDate) {
      setDate(selectedDate);
    }
  }, [selectedDate, setDate]);

  return (
    <div className="relative flex flex-col justify-center mb-4">
      {/* Read-only input */}
      <input
        required
        ref={inputRef}
        type="text"
        defaultValue={selectedDate ? formatDate(selectedDate) : ''}
        onKeyDown={(e) => e.preventDefault()}
        inputMode="none"
        onClick={toggleDropdown}
        placeholder="Select Date"
        className={`input input-bordered w-full cursor-pointer caret-transparent ${
          !selectedDate ? 'text-gray-400' : ''
        }`}
      />
      <FaCalendar className="absolute right-5 black pointer-events-none cursor-pointer" onClick={toggleDropdown} />

      {/* Calendar Dropdown */}
      {isOpen && (
        <div
          ref={dropdownRef}
          className="absolute top-full mt-2 left-0 z-[1000]"
        >
          <CalendarPicker
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            closePicker={closePicker}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
