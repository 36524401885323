import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Title and Intro Section */}
        <h1 className="text-5xl font-extrabold text-blue-600 text-center">
          5 Reasons Why Ride Sharing is the Future of Travel
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Whether you're planning a long-distance road trip or looking for an eco-friendly commute, ride sharing is quickly becoming the go-to option for travelers seeking affordable and sustainable ways to travel. Here's why ride sharing is the future of travel.
        </p>

        {/* Reason 1: Cost-Effective Travel */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            1. Cost-Effective Travel for Everyone
          </h2>
          <p className="text-lg text-gray-700">
            Traveling solo can be expensive. From fuel to tolls and maintenance, the costs quickly add up. That’s why <strong>ride-sharing apps for travelers</strong> like Ride Share are a game changer. By splitting costs with other travelers, you can make long-distance and daily commutes much more affordable.
          </p>
          <p className="text-lg text-gray-700">
            Whether you’re looking for <strong>cheap rides for long-distance trips</strong> or <strong>budget-friendly options for short commutes</strong>, ride sharing helps make travel more affordable for everyone.
          </p>
        </section>

        {/* Reason 2: Eco-Friendly Carpooling */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            2. Eco-Friendly Carpooling Solutions
          </h2>
          <p className="text-lg text-gray-700">
            Sustainability is more important than ever, and <strong>eco-friendly carpooling solutions</strong> are one of the most effective ways to reduce your <strong>carbon footprint</strong>. By sharing rides, fewer cars are on the road, which means less traffic congestion and reduced emissions.
          </p>
          <p className="text-lg text-gray-700">
            <strong>Ride sharing for long-distance trips</strong> is an especially impactful way to travel while minimizing environmental damage. Platforms like Ride Share make it easy to find <strong>eco-conscious travel companions</strong>.
          </p>
        </section>

        {/* Reason 3: Building Community */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            3. Building Community through Ride Sharing
          </h2>
          <p className="text-lg text-gray-700">
            One of the best parts of <strong>ride sharing</strong> is the connections you make. Whether you're traveling with locals or fellow adventurers, sharing a ride is a great way to <strong>meet new people</strong> and <strong>find travel companions for road trips</strong>. Ride Share turns your travel experience into a social one, fostering community and friendships along the way.
          </p>
        </section>

        {/* Reason 4: Flexibility and Convenience */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            4. Flexible and Convenient Travel
          </h2>
          <p className="text-lg text-gray-700">
            Unlike public transportation, <strong>ride-sharing services</strong> offer greater flexibility. With Ride Share, you can schedule your rides to fit your plans, adjust pickup and drop-off points, and avoid the rigid schedules of buses or trains.
          </p>
          <p className="text-lg text-gray-700">
            Whether you're planning a <strong>shared ride for vacation</strong> or need an easy solution for your daily commute, <strong>ride-sharing apps</strong> provide the flexibility you need.
          </p>
        </section>

        {/* Reason 5: Reducing Traffic and Congestion */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            5. Reducing Traffic and Road Congestion
          </h2>
          <p className="text-lg text-gray-700">
            More cars on the road mean more traffic and stress. <strong>Ride sharing</strong> helps reduce the number of vehicles on the road, making travel faster and smoother for everyone.
          </p>
          <p className="text-lg text-gray-700">
            If you’ve ever wondered, "Can I <strong>share a ride for long-distance trips</strong>?" the answer is yes! Ride Share allows you to enjoy your trip while contributing to a less congested and more sustainable transportation network.
          </p>
        </section>

        {/* Conclusion Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Conclusion
          </h2>
          <p className="text-lg text-gray-700">
            <strong>Ride sharing</strong> is more than just a way to save money—it's an eco-friendly, community-focused solution that offers flexibility and convenience for <strong>travelers</strong>. Whether you're looking for <strong>cheap rides</strong>, <strong>eco-friendly travel options</strong>, or the chance to meet fellow travelers, Ride Share is the future of travel.
          </p>
          <p className="text-lg text-gray-700">
            Ready to join the future of travel? <Link to="/register" className="text-blue-500 font-bold">Sign up today</Link> and start sharing your rides with Ride Share!
          </p>
        </section>

        {/* Call to Action */}
        <div className="text-center py-8">
          <Link to="/register">
            <button className="btn btn-primary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
              Join Ride Share Now!
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
