import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Title and Intro Section */}
        <h1 className="text-5xl font-extrabold text-blue-600 text-center">
          How to Stay Safe While Ride Sharing
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Ride sharing offers convenience and cost-saving benefits, but it’s important to prioritize your safety. Learn how to stay safe during your next ride-sharing experience with these practical tips.
        </p>

        {/* Section 1: Choose a Reputable Ride-Sharing Platform */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            1. Choose a Reputable Ride-Sharing Platform
          </h2>
          <p className="text-lg text-gray-700">
            Always choose a trustworthy platform like Ride Share that verifies drivers and passengers, providing user reviews and identity checks. This helps ensure that you’re using a safe and reliable service.
          </p>
        </section>

        {/* Section 2: Check Profiles and Reviews Before You Travel */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            2. Check Profiles and Reviews Before You Travel
          </h2>
          <p className="text-lg text-gray-700">
            Before accepting a ride or a passenger, check their profile and reviews. Look for verified identities and positive feedback from other users to ensure you’re traveling with someone reliable.
          </p>
        </section>

        {/* Section 3: Share Your Trip Details with Friends or Family */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            3. Share Your Trip Details with Friends or Family
          </h2>
          <p className="text-lg text-gray-700">
            Before starting your journey, share key trip details—such as your driver’s profile and expected arrival time—with a trusted family member or friend to add an extra layer of security.
          </p>
        </section>

        {/* Section 4: Meet in a Safe Location */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            4. Meet in a Safe Location
          </h2>
          <p className="text-lg text-gray-700">
            Always meet your driver or passenger in a public, well-lit location. Avoid giving your home address for pickups and choose busy, public places like bus stops, train stations, or shopping malls.
          </p>
        </section>

        {/* Section 5: Trust Your Instincts */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            5. Trust Your Instincts
          </h2>
          <p className="text-lg text-gray-700">
            If you ever feel uncomfortable during a ride, trust your instincts. Don’t hesitate to cancel the ride, exit the vehicle in a public place, or report any suspicious behavior to the platform.
          </p>
        </section>

        {/* Section 6: Keep Your Belongings Secure */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            6. Keep Your Belongings Secure
          </h2>
          <p className="text-lg text-gray-700">
            Keep your personal belongings in sight during the ride. Avoid placing valuables in the trunk or out of your control to prevent theft. For drivers, secure personal items and avoid leaving them in plain sight.
          </p>
        </section>

        {/* Conclusion Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Conclusion
          </h2>
          <p className="text-lg text-gray-700">
            Safety should always be your top priority when using a **ride-sharing service**. By choosing a reputable platform, checking profiles, sharing your trip details, and following these tips, you can enjoy a safe and secure ride-sharing experience.
          </p>
          <p className="text-lg text-gray-700">
            Ready to travel with confidence? <Link to="/start" className="text-blue-500 font-bold">Join Ride Share</Link> today for a safer, more reliable way to share rides!
          </p>
        </section>

      </div>
    </div>
  );
};

export default BlogPost;
