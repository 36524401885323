import React from 'react';

const CalendarPicker = ({ selectedDate, setSelectedDate, closePicker }) => {
  const today = new Date(); // Get today's date
  const currentDate = selectedDate || today;

  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const generateCalendarDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1).getDay(); // Get the weekday the month starts on
    const totalDays = daysInMonth(year, month);
    const days = [];

    // Add empty days to match the start day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(null); // Push `null` for blank spaces before the first day of the month
    }

    // Add actual days of the month
    for (let i = 1; i <= totalDays; i++) {
      const dayDate = new Date(year, month, i);
      dayDate.setHours(0, 0, 0, 0); // Set time to midnight to avoid time zone issues
      days.push({
        day: i,
        isDisabled: dayDate < today.setHours(0, 0, 0, 0), // Disable past days
      });
    }

    return days;
  };

  const generateYearOptions = () => {
    const currentYear = today.getFullYear();
    return Array.from({ length: 100 }, (_, i) => currentYear + i); // Next 100 years
  };

  const handleDateSelect = (day) => {
    const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    newDate.setHours(0, 0, 0, 0); // Set time to midnight to avoid time zone issues
    if (newDate >= today.setHours(0, 0, 0, 0)) {
      setSelectedDate(newDate);
      closePicker(); // Close the picker when a valid future date is selected
    }
  };

  const handleMonthSelect = (month) => {
    const day = selectedDate ? selectedDate.getDate() : 1; // If a date is selected, keep the day
    const newDate = new Date(currentDate.getFullYear(), parseInt(month), day);
    newDate.setHours(0, 0, 0, 0); // Set time to midnight
    if (newDate >= today.setHours(0, 0, 0, 0)) {
      setSelectedDate(newDate);
    }
  };

  const handleYearSelect = (year) => {
    const selectedYear = parseInt(year);
    const selectedMonth = currentDate.getMonth();

    let newDate;

    if (selectedYear === today.getFullYear()) {
      // If selecting the current year, check if the selected month is in the past
      const closestFutureMonth = selectedMonth >= today.getMonth() ? selectedMonth : today.getMonth();

      // If the selected month is still in the future, preserve it. Otherwise, select the closest future month
      newDate = new Date(selectedYear, closestFutureMonth, selectedDate ? selectedDate.getDate() : 1);
    } else {
      // For future years, keep the selected date and month
      const day = selectedDate ? selectedDate.getDate() : 1;
      newDate = new Date(selectedYear, selectedMonth, day);
    }

    newDate.setHours(0, 0, 0, 0); // Set time to midnight
    setSelectedDate(newDate);
  };

  return (
    <div className="w-72 p-4 bg-white border rounded-lg shadow-lg z-[1000]">
      {/* Year and Month Selectors */}
      <div className="flex justify-between items-center mb-4">
        {/* Year Dropdown */}
        <select
          className="select select-bordered text-sm"
          value={currentDate.getFullYear()}
          onChange={(e) => handleYearSelect(e.target.value)}
        >
          {generateYearOptions().map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>

        {/* Month Dropdown */}
        <select
          className="select select-bordered text-sm"
          value={currentDate.getMonth()}
          onChange={(e) => handleMonthSelect(e.target.value)}
        >
          {Array.from({ length: 12 }, (_, i) => (
            <option
              key={i}
              value={i}
              disabled={
                currentDate.getFullYear() === today.getFullYear() &&
                i < today.getMonth()
              }
            >
              {new Date(0, i).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
      </div>

      {/* Weekday labels */}
      <div className="grid grid-cols-7 gap-2">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <div key={day} className="text-center font-bold text-xs">
            {day}
          </div>
        ))}

        {/* Calendar days */}
        {generateCalendarDays().map((dayObj, index) => (
          <div
            key={index}
            className={`p-2 text-center rounded-lg cursor-pointer text-sm ${
              dayObj
                ? dayObj.isDisabled
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-gray-200 hover:bg-blue-300'
                : ''
            } ${
              selectedDate &&
              dayObj &&
              selectedDate.getDate() === dayObj.day &&
              currentDate.getMonth() === selectedDate.getMonth()
                ? 'bg-orange-500 text-white'
                : ''
            }`}
            onClick={() => dayObj && !dayObj.isDisabled && handleDateSelect(dayObj.day)}
          >
            {dayObj ? dayObj.day : ''}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarPicker;
