import { useParams, Navigate } from 'react-router-dom';
import BlogPost1 from './posts/1/BlogPost';
import BlogPost2 from './posts/2/BlogPost';
import BlogPost3 from './posts/3/BlogPost';
import BlogPost4 from './posts/4/BlogPost';
import BlogPost5 from './posts/5/BlogPost';
import BlogPost6 from './posts/6/BlogPost'; 
import BlogPost7 from './posts/7/BlogPost'; 
import BlogPost8 from './posts/8/BlogPost'; 
import BlogPost9 from './posts/9/BlogPost'; 
import BlogPost10 from './posts/10/BlogPost';

const BlogPost = () => {
  const { postSlug } = useParams();

  // Dynamic mapping of slug to specific blog post component
  const blogPosts = {
    'the-ultimate-guide-to-ride-sharing': <BlogPost1 />,
    '5-reasons-why-ride-sharing-is-the-future': <BlogPost2 />,
    'how-ride-sharing-saves-money': <BlogPost3 />,
    '5-tips-for-planning-a-shared-ride-for-vacation': <BlogPost4 />,
    'how-to-find-travel-companions-for-long-distance-rides': <BlogPost5 />,
    'eco-friendly-benefits-of-ride-sharing': <BlogPost6 />, 
    'how-to-stay-safe-while-ride-sharing': <BlogPost7 />,  
    'best-ride-sharing-apps-for-travelers': <BlogPost8 />, 
    'how-ride-sharing-reduces-your-carbon-footprint': <BlogPost9 />, 
    'ride-sharing-vs-car-rentals-which-is-better': <BlogPost10 />, 
  };

  // Return the corresponding blog post component or a fallback if not found
  return blogPosts[postSlug] || <Navigate to="/blog" />;
};

export default BlogPost;
