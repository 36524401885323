import React, { useState } from 'react';

const MessageItem = ({ message, currentUserId }) => {
  const [showTime, setShowTime] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false); // To control overlay visibility
  const [pressTimer, setPressTimer] = useState(null); // To track the long press
  const [isLongPressed, setIsLongPressed] = useState(false); // To avoid triggering click after long press

  const isCurrentUser = message.senderId === currentUserId;

  const copyMessageToClipboard = () => {
    navigator.clipboard.writeText(message.text); // Copy text to clipboard
    setShowOverlay(true); // Show the "Copied!" overlay instantly

    // Start timer to fade out the "Copied!" message after 2 seconds
    setTimeout(() => {
      setShowOverlay(false); // Hide the overlay after the fade-out
    }, 1000); // 2 seconds to show, then fade out
  };

  const handleMouseDown = () => {
    const timer = setTimeout(() => {
      setIsLongPressed(true); // Indicate a long press occurred
      copyMessageToClipboard(); // Copy to clipboard on long press
    }, 500); // Trigger long press after 1 second
    setPressTimer(timer);
  };

  const handleMouseUp = () => {
    clearTimeout(pressTimer); // Clear the long press timer
    setTimeout(() => {
      setIsLongPressed(false); // Reset long press flag
    }, 50); // Small delay to ensure long press doesn’t trigger click
  };

  const handleClick = () => {
    if (!isLongPressed) {
      setShowTime(!showTime); // Toggle showing the timestamp on single click
    }
  };

  return (
    <li
      className={`relative p-3 rounded-lg max-w-xs lg:max-w-md w-fit select-none ${
        isCurrentUser ? 'bg-green-200' : 'bg-blue-200'
      }`}
      onClick={handleClick}
      onMouseDown={handleMouseDown} // Start long press detection
      onMouseUp={handleMouseUp}     // End long press detection
      onTouchStart={handleMouseDown} // Mobile support for long press
      onTouchEnd={handleMouseUp}     // Mobile support for long press
    >
      <p>{message.text}</p>
      {showTime && (
        <span className="text-xs">
          {new Date(message.timestamp.seconds * 1000).toLocaleString()}
        </span>
      )}

      {/* Copied overlay */}
      <div
        className={`absolute bottom-3 right-3 translate-x-full translate-y-full bg-gray-500 text-white text-sm px-2 py-1 rounded ${
          showOverlay ? 'opacity-100' : 'opacity-0 transition-opacity duration-1000'
        }`}
      >
        Copied!
      </div>
    </li>
  );
};

export default MessageItem;
