import React, { useEffect, useRef } from 'react';

const MapComponent = ({ matchDetails, tripData }) => {    
    const mapRef = useRef();
    useEffect(() => {
            const map = new window.google.maps.Map(mapRef.current, {
                zoom: 10,
                center: matchDetails.startCoords, // Center the map initially around the trip's startCoords
                disableDefaultUI: true,
                zoomControl: true,
            });
            
            // own route
            if(false){ //radius
                new window.google.maps.Circle({
                    map: map,
                    center: tripData.startCoords,
                    radius: tripData.startRadius * 1000, // Convert km to meters
                    fillColor: '#0000FF',
                    fillOpacity: 0.2,
                    strokeColor: '#0000FF',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                });
                new window.google.maps.Circle({
                    map: map,
                    center: tripData.endCoords,
                    radius: tripData.endRadius * 1000, // Convert km to meters
                    fillColor: '#0000FF',
                    fillOpacity: 0.2,
                    strokeColor: '#0000FF',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                });
            }
            new window.google.maps.Marker({
                position: tripData.startCoords,
                map: map,
                icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 6,
                    fillColor: '#0000FF',
                    fillOpacity: 1,
                    strokeWeight: 0,
                },
                title: 'Your Start',
            });
            new window.google.maps.Marker({
                position: tripData.endCoords,
                map: map,
                icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 6,
                    fillColor: '#0000FF',
                    fillOpacity: 1,
                    strokeWeight: 0,
                },
                title: 'Your End',
            });
            const userOriginalRoute = new window.google.maps.Polyline({
                path: [tripData.startCoords, tripData.endCoords],
                geodesic: false,
                strokeColor: '#0000FF', // Blue for the user's route
                strokeOpacity: 1.0,
                strokeWeight: 4,
            });
            userOriginalRoute.setMap(map);

            // matches route
            if(false){ // radius
                new window.google.maps.Circle({
                    map: map,
                    center: matchDetails.startCoords,
                    radius: matchDetails.startRadius * 1000, // Convert km to meters
                    fillColor: '#FF0000',
                    fillOpacity: 0.2,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                });
                new window.google.maps.Circle({
                    map: map,
                    center: matchDetails.endCoords,
                    radius: matchDetails.endRadius * 1000, // Convert km to meters
                    fillColor: '#FF0000',
                    fillOpacity: 0.2,
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                });
            }
            new window.google.maps.Marker({
                position: matchDetails.startCoords,
                map: map,
                icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 6,
                    fillColor: '#FF0000',
                    fillOpacity: 1,
                    strokeWeight: 0,
                },
                title: 'Trip Start',
            });   
            new window.google.maps.Marker({
                position: matchDetails.endCoords,
                map: map,
                icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 6,
                fillColor: '#FF0000',
                fillOpacity: 1,
                strokeWeight: 0,
                },
                title: 'Trip End'
            });
            const tripRoute = new window.google.maps.Polyline({
                path: [matchDetails.startCoords, matchDetails.endCoords],
                geodesic: false,
                strokeColor: '#FF0000', // Red for the trip route
                strokeOpacity: 1.0,
                strokeWeight: 4,
                });
            tripRoute.setMap(map);


            // Extend the map bounds to include all points (trip and user points)
            // Define bounds to ensure all points are visible
            const bounds = new window.google.maps.LatLngBounds();
            bounds.extend(matchDetails.startCoords);
            bounds.extend(matchDetails.endCoords);
            bounds.extend(tripData.startCoords);
            bounds.extend(tripData.endCoords);
            map.fitBounds(bounds);
    }, []);

    return <div ref={mapRef} className="w-full h-72 mb-6"></div>;
};

export default MapComponent;
