import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'; // Firebase methods for sign-up
import EmailField from './components/EmailField';
import PasswordField from './components/PasswordField';
import GoogleSignIn from './components/GoogleSignIn';
import Loader from '../../components/Loader';

const Register = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const auth = getAuth();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
            }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true); // Set loading to true during sign-up

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false); // Stop loading after the process completes
        }
    }

    return (
        <div className="flex flex-col justify-between p-[40px]">
        {/* Register Form */}
        <div className="w-full max-w-md mx-auto bg-white p-8 rounded shadow-md">
            <h2 className="text-2xl font-semibold text-center mb-6">Register</h2>
            <form onSubmit={handleSubmit}>
            <EmailField
                email="email"
                value={formData.email}
                onChange={handleInputChange}
                disabled={loading} // Disable input field during loading
            />
            <PasswordField
                password="password"
                value={formData.password}
                onChange={handleInputChange}
                disabled={loading} // Disable input field during loading
            />

            {error && <p className="text-red-500 text-center mb-4">{error=='terms'?'You must accept the Terms Of Use to register.':error}</p>}

            {/* Submit Button */}
            <div className="flex justify-center mb-4">
                <button type="submit" className="btn btn-primary w-full" disabled={loading}>
                {loading ? 'Registering...' : 'Register'}
                </button>
            </div>
            </form>

            {/* Google Sign In */}
            <GoogleSignIn setLoading={setLoading} register={true} setError={setError}/>
            {loading && <Loader/>}
        </div>
        </div>
    )
};

export default Register;
