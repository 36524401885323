import { useNotifications } from '../../components/NotificationsProvider';
import { Link } from 'react-router-dom';
import ChatListItem from './components/ChatListItem';
import Loader from '../../components/Loader'; // Import your Loader component

const ChatList = () => {
    const { activeChats, loading } = useNotifications();

    return (
        <div className="min-h-screen pb-4">
            <h2 className='text-2xl m-5 font-semibold'>Active chats:</h2>
        {loading ? (
            <Loader /> // Show the Loader component while loading
        ) : activeChats.length > 0 ? (
            <ul className="divide-y divide-gray-300"> {/* Adds dividers without gaps */}
            {activeChats.map((chat, index) => <ChatListItem key={chat.id} chat={chat} index={index} />)}
            </ul>
        ) : (
            <p className="max-w-full text-center md:max-w-4xl mx-auto bg-white p-4 md:p-6 rounded-lg shadow-md">
                You don't have any active chats yet.<br/>
                <Link to="/create-trip">
                    <button className="btn btn-primary px-6 py-3 font-semibold rounded-lg mt-4">
                        Plan a Trip & Share the Ride!
                    </button>
                </Link>
            </p>
        )}
        </div>
    );
};

export default ChatList;
