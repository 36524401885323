import React from 'react';
import Step from './Step';
import signupImg from 'assets/images/land/signup.svg';
import makeImg from 'assets/images/land/make.svg';
import goImg from 'assets/images/land/go.svg';

const steps = [
  {
    img: signupImg,
    title: 'Post Your Trip',
    text: 'Enter your trip details including starting point, destination, and travel date to find travel companions.'
  },
  {
    img: makeImg,
    title: 'Find a Match',
    text: 'Browse and get matched with travelers heading in the same direction for long-distance rides or daily commutes.'
  },
  {
    img: goImg,
    title: 'Connect, Share, Go!',
    text: 'Chat with your matches to coordinate details, share the cost, and make new friends on the road.'
  }
];

const HowItWorks = () => (
  <div className="bg-gray-100 py-24">
    <div className="max-w-7xl mx-auto px-4">
      <h2 className="text-4xl font-bold text-center mb-12">How It Works</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {steps.map(step => <Step key={step.title} {...step} />)}
      </div>
    </div>
  </div>
);

export default HowItWorks;
