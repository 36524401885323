import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Title and Intro Section */}
        <h1 className="text-5xl font-extrabold text-blue-600 text-center">
          How Ride Sharing Helps You Save Money on Long-Distance Trips
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Long-distance travel can be costly, but with ride sharing, you can reduce expenses and make your trip more affordable. Here’s how ride sharing helps you save on your next journey.
        </p>

        {/* Section 1: Split Fuel Costs */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            1. Split Fuel Costs with Other Travelers
          </h2>
          <p className="text-lg text-gray-700">
            Fuel is one of the largest expenses on a long-distance road trip. By <strong>ride sharing</strong> with other travelers, you can divide this cost among the passengers, significantly reducing what each person pays.
          </p>
          <p className="text-lg text-gray-700">
            For example, a solo 500-mile trip might cost you a lot in fuel, but with <strong>carpooling for long-distance trips</strong>, you can cut this cost down by sharing the ride with others.
          </p>
        </section>

        {/* Section 2: Avoid Tolls and Parking Fees */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            2. Avoid Expensive Tolls and Parking Fees
          </h2>
          <p className="text-lg text-gray-700">
            Long-distance travel often comes with hidden costs like tolls and parking fees. When you <strong>share a ride</strong>, these fees are split among all travelers, making it far more affordable than paying everything yourself.
          </p>
          <p className="text-lg text-gray-700">
            Whether you're the driver or a passenger, <strong>ride-sharing services</strong> like Ride Share allow you to <strong>share travel expenses</strong>, including these additional fees.
          </p>
        </section>

        {/* Section 3: Reduce Vehicle Wear and Tear */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            3. Reduce Vehicle Wear and Tear
          </h2>
          <p className="text-lg text-gray-700">
            Long road trips can cause wear and tear on your vehicle, leading to more frequent maintenance and repairs. By <strong>ride sharing</strong>, you reduce the need to drive your own car, which helps you save money in the long run.
          </p>
          <p className="text-lg text-gray-700">
            <strong>Ride-sharing for long-distance trips</strong> is a great way to extend the life of your vehicle by cutting down on the miles you put on it.
          </p>
        </section>

        {/* Section 4: Travel in Comfort Without High Costs */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            4. Travel in Comfort Without the High Costs
          </h2>
          <p className="text-lg text-gray-700">
            Public transportation options like buses and trains can be expensive and restrictive. <strong>Ride-sharing services</strong> give you the comfort and flexibility of a car ride without the high costs.
          </p>
          <p className="text-lg text-gray-700">
            You can plan your trip, make stops along the way, and enjoy a more comfortable journey—without breaking the bank.
          </p>
        </section>

        {/* Section 5: Skip Rental Car Costs */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            5. Skip Rental Car Costs
          </h2>
          <p className="text-lg text-gray-700">
            Renting a car for a road trip can be convenient, but it also comes with hefty costs, including rental fees, insurance, and fuel. By choosing <strong>ride sharing</strong>, you can avoid these costs entirely.
          </p>
          <p className="text-lg text-gray-700">
            Ride Share allows you to <strong>find a shared ride</strong> without the need for a rental, making it a cost-effective solution for long-distance travelers.
          </p>
        </section>

        {/* Section 6: Meet Like-Minded Travelers and Save Together */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            6. Meet Like-Minded Travelers and Save Together
          </h2>
          <p className="text-lg text-gray-700">
            One of the best parts of <strong>ride sharing</strong> is that it allows you to connect with other travelers. Not only do you <strong>split travel costs</strong>, but you also get to meet new people, share stories, and make your journey more enjoyable.
          </p>
          <p className="text-lg text-gray-700">
            <strong>Find travel companions for long-distance rides</strong> and make your trip not just affordable, but also memorable.
          </p>
        </section>

        {/* Conclusion Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Conclusion
          </h2>
          <p className="text-lg text-gray-700">
            Traveling long distances doesn’t have to be expensive. With Ride Share, you can save on fuel, avoid tolls and parking fees, reduce wear on your vehicle, and enjoy a comfortable journey—all while meeting new people.
          </p>
          <p className="text-lg text-gray-700">
            Ready to save on your next trip? <Link to="/register" className="text-blue-500 font-bold">Join Ride Share</Link> today and discover how <strong>ride sharing</strong> can make your travel experience more affordable and enjoyable.
          </p>
        </section>

        {/* Call to Action */}
        <div className="text-center py-8">
          <Link to="/register">
            <button className="btn btn-primary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
              Join Ride Share Now!
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
