import React, { forwardRef } from 'react';

// A reusable textarea field component using forwardRef to pass the ref
const TextareaField = forwardRef(({ label, name, defaultValue, disabled }, ref) => {
  return (
    <div>
      <label className="block font-semibold mb-1">{label}</label>
      <textarea
        name={name}
        ref={ref}
        defaultValue={defaultValue}
        className="w-full border border-gray-300 rounded-md p-2"
        rows="4"
        disabled={disabled}
      ></textarea>
    </div>
  );
});

export default TextareaField;
