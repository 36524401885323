import React, { useState } from 'react';
import { getAuth } from 'firebase/auth';
import EmailInput from './EmailInput';
import OtpInput from './OtpInput';

const ChangeEmailOverlay = ({ onClose }) => {
  const [error, setError] = useState(null); // Handle errors
  const [loading, setLoading] = useState(false); // Loading state to disable buttons
  const [otpSent, setOtpSent] = useState(false); // Flag to track OTP sending stage
  const [successMessage, setSuccessMessage] = useState(''); // Success message state

  const auth = getAuth();
  const user = auth.currentUser; // Get current authenticated user

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-md w-[90%] max-w-md">
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {successMessage ? (
          <div>
            <h2 className="text-xl font-semibold mb-4">Email Updated</h2>
            <p className="text-sm text-gray-500">{successMessage}</p>
            <p className="text-sm text-gray-500">Please login with the new Email.</p>
            <div className="flex justify-end mt-6">
              <button
                type="button"
                onClick={() => { window.open("/", "_self"); }}
                className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        ) : (
          <div>
            {otpSent ? (
              <OtpInput
                setLoading={setLoading}
                setError={setError}
                setSuccessMessage={setSuccessMessage}
                loading={loading}
                onClose={onClose}
              />
            ) : (
              <EmailInput
                user={user}
                setOtpSent={setOtpSent}
                setLoading={setLoading}
                setError={setError}
                loading={loading}
                onClose={onClose}
              />
            )}
            
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangeEmailOverlay;
