import React, { useEffect, useRef } from 'react';
var google;
const MapComponent = ({ startCoords, endCoords, startRadius, endRadius }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    google = window.google;
    const mapInstance = new google.maps.Map(mapRef.current, {
        zoom: 8,
        disableDefaultUI: true, // Disable default controls
        zoomControl: true, // Disable zoom control
        // gestureHandling: 'none', // Disable map interaction
    });

    const bounds = new google.maps.LatLngBounds();

    // Add start marker
    const startMarker = new google.maps.Marker({
        position: startCoords,
        map: mapInstance,
        title: 'Start Location',
        icon: {
            url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png", // Blue marker for end location
        },
    });
    bounds.extend(startMarker.getPosition());

    // Add end marker
    const endMarker = new google.maps.Marker({
        position: endCoords,
        map: mapInstance,
        title: 'End Location',
        icon: {
            url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Blue marker for end location
        },
    });
    bounds.extend(endMarker.getPosition());

    // Fit map to bounds to include both markers
    mapInstance.fitBounds(bounds);

    // draw line
    const line = new window.google.maps.Polyline({
        path: [startCoords, endCoords],
        geodesic: false,
        strokeColor: 'green', // Red for the trip route
        strokeOpacity: 1.0,
        strokeWeight: 4,
    });
    line.setMap(mapInstance);

    // draw radius       
    const newStartCircle = new window.google.maps.Circle({
        map: mapInstance,
        center: startCoords,
        radius: startRadius * 1000, // Convert km to meters
        fillColor: '#FF0000',
        fillOpacity: 0.2,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
    });
    
    const newEndCircle = new window.google.maps.Circle({
        map: mapInstance,
        center: endCoords,
        radius: endRadius * 1000, // Convert km to meters
        fillColor: '#0000FF',
        fillOpacity: 0.2,
        strokeColor: '#0000FF',
        strokeOpacity: 0.8,
        strokeWeight: 2,
    });
  }, []);

  return <div ref={mapRef} className="w-full h-64"></div>;
};

export default MapComponent;
