import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Title and Intro Section */}
        <h1 className="text-5xl font-extrabold text-blue-600 text-center">
          The Best Ride-Sharing Apps for Travelers
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Whether you’re traveling across town or embarking on a long-distance trip, ride-sharing apps can make your journey more convenient, affordable, and eco-friendly. Here are the top ride-sharing apps for travelers.
        </p>

        {/* Ride Share Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            1. Ride Share
          </h2>
          <p className="text-lg text-gray-700">
            <strong>Ride Share</strong> is ideal for travelers looking for affordable, eco-friendly, and flexible travel options. It’s designed for long-distance trips, vacations, and commuting, allowing you to find travel companions and split costs.
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Trip Matching</strong>: Connect with travelers heading in the same direction.</li>
            <li><strong>User Reviews</strong>: Find reliable travel companions with verified profiles.</li>
            <li><strong>Eco-Friendly Travel</strong>: Reduce your carbon footprint by sharing rides.</li>
          </ul>
        </section>

        {/* BlaBlaCar Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            2. BlaBlaCar
          </h2>
          <p className="text-lg text-gray-700">
            <strong>BlaBlaCar</strong> is one of the largest ride-sharing platforms for long-distance travel, especially popular in Europe and Asia. It connects drivers with passengers heading in the same direction, allowing you to split costs and make new friends.
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Wide Coverage</strong>: Available in over 20 countries.</li>
            <li><strong>Community Focus</strong>: Strong community element with user profiles and reviews.</li>
            <li><strong>Flexible Options</strong>: Suitable for both long-distance and short trips.</li>
          </ul>
        </section>

        {/* Lyft Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            3. Lyft
          </h2>
          <p className="text-lg text-gray-700">
            <strong>Lyft</strong> is a popular option for city commuting across the U.S. and Canada. Although it’s mostly used for short trips, Lyft’s <strong>Lyft Line</strong> feature allows you to share rides with other passengers heading in the same direction.
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>On-Demand Rides</strong>: Request a ride anytime.</li>
            <li><strong>Rideshare Variety</strong>: Choose from options like Lyft Line for shared rides.</li>
            <li><strong>Safety Features</strong>: Includes in-app emergency assistance and driver/passenger verification.</li>
          </ul>
        </section>

        {/* Uber Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            4. Uber
          </h2>
          <p className="text-lg text-gray-700">
            <strong>Uber</strong> is a global ride-sharing platform with services in over 900 cities. It’s a popular choice for both short commutes and long-distance travel, offering options like <strong>UberPool</strong> for shared rides.
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Global Availability</strong>: Available in cities worldwide.</li>
            <li><strong>Multiple Ride Options</strong>: Choose from UberPool, UberX, and other options.</li>
            <li><strong>Airport Transfers</strong>: Convenient for airport pickups and drop-offs.</li>
          </ul>
        </section>

        {/* Via Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            5. Via
          </h2>
          <p className="text-lg text-gray-700">
            <strong>Via</strong> focuses on providing shared rides within cities. All rides are shared, making it an affordable and environmentally friendly option for urban travel.
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Shared Rides</strong>: Every ride is shared, keeping costs low.</li>
            <li><strong>Eco-Friendly</strong>: Fewer cars on the road mean lower emissions.</li>
            <li><strong>Fixed Prices</strong>: Know the fare before you start your trip.</li>
          </ul>
        </section>

        {/* Splt Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            6. Splt
          </h2>
          <p className="text-lg text-gray-700">
            <strong>Splt</strong> connects corporate travelers and commuters heading in the same direction. It’s ideal for work-related travel and offers cost-saving carpooling options.
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li><strong>Corporate Carpooling</strong>: Perfect for work trips or commuting with colleagues.</li>
            <li><strong>Cost Savings</strong>: Split the cost of your rides with others.</li>
            <li><strong>Eco-Friendly</strong>: Helps reduce emissions by carpooling.</li>
          </ul>
        </section>

        {/* Conclusion Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Conclusion
          </h2>
          <p className="text-lg text-gray-700">
            With so many ride-sharing options available, travelers can easily find a platform that fits their needs. Whether you’re looking for a quick city ride or planning a long-distance trip, there’s a ride-sharing app for you.
          </p>
          <p className="text-lg text-gray-700">
            Ready to share your next trip? <Link to="/register" className="text-blue-500 font-bold">Join Ride Share</Link> today and connect with fellow travelers for your next adventure!
          </p>
        </section>

        {/* Call to Action */}
        <div className="text-center py-8">
          <Link to="/register">
            <button className="btn btn-primary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
              Join Ride Share Now!
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
