import React, { useState, useEffect } from 'react';
import countries from 'assets/countries.json';

const CountrySelect = ({ defaultValue, onChange, disabled }) => {
  const [selectedCountry, setSelectedCountry] = useState(defaultValue);

  // Update the selected country when defaultValue changes
  useEffect(() => {
    setSelectedCountry(defaultValue);
  }, [defaultValue]);

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    onChange(e.target.value); // Pass the selected value to the parent
  };

  return (
    <div>
      <label className="block font-semibold mb-1">Country</label>
      <select
        value={selectedCountry}
        onChange={handleCountryChange}
        className="border border-gray-300 p-2 rounded-md w-full"
        disabled={disabled}
      >
        {countries.map((country) => (
          <option key={country.code} value={country.name}>
            {country.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CountrySelect;
