import React, { useState, useEffect } from 'react';
import SearchResultItem from '../../SearchResults/SearchResultItem.js';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { calculateRouteOverlap, calculateTimeMatch } from '../../SearchResults/components/searchTrips';

const SavedMatches = ({ tripData }) => {
  const [savedMatches, setSavedMatches] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state to show while fetching
  const [sortOption, setSortOption] = useState('average'); // Default sorting by average
  const db = getFirestore();
  const tripId = tripData.id;
  const removeMatch = (matchId) => {
    // Update the savedMatches state by filtering out the match with the specified ID
    setSavedMatches((prevMatches) => prevMatches.filter((match) => match.tripDetails.id !== matchId));
  };
  useEffect(() => {
    const fetchSavedMatches = async () => {
      try {
        const tripDocRef = doc(db, 'trips', tripId);
        const tripDoc = await getDoc(tripDocRef);

        if (tripDoc.exists()) {
          const savedMatchIds = tripDoc.data().matches || []; // Get the matches array from Firestore

          // Fetch detailed match data for each saved match
          const matchPromises = savedMatchIds.map(async (matchId) => {
            const matchDocRef = doc(db, 'trips', matchId);
            const matchDoc = await getDoc(matchDocRef);
            if (matchDoc.exists()) {
              const matchData = matchDoc.data();
              
              // Calculate route overlap and time match
              const routeOverlap = calculateRouteOverlap(tripData, matchData);
              const timeMatch = calculateTimeMatch(tripData, matchData);

              return {
                ...matchData,
                routeOverlap,
                timeMatch,
                tripDetails: { id: matchId, ...matchData }
              };
            } else {
              console.error(`Match with ID ${matchId} not found`);
              return null;
            }
          });

          const fetchedMatches = await Promise.all(matchPromises);
          setSavedMatches(fetchedMatches.filter((match) => match !== null)); // Filter out any null values
        }
      } catch (error) {
        console.error('Error fetching saved matches:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchSavedMatches();
  }, [db, tripId, tripData]);

  // Sort matches based on the selected option
  const sortMatches = (matches, option) => {
    return matches.sort((a, b) => {
      if (option === 'route') {
        return b.routeOverlap - a.routeOverlap; // Sort by route match (descending)
      } else if (option === 'time') {
        return b.timeMatch - a.timeMatch; // Sort by time match (descending)
      } else {
        // Default: sort by average of route and time match
        const avgA = (a.routeOverlap + a.timeMatch) / 2;
        const avgB = (b.routeOverlap + b.timeMatch) / 2;
        return avgB - avgA; // Sort by average (descending)
      }
    });
  };

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  if (loading) {
    return <p>Loading saved matches...</p>;
  }

  const sortedMatches = sortMatches([...savedMatches], sortOption); // Sort matches based on current sort option

  return (
    <div className="mt-8">
      <h3 className="text-xl font-bold mb-4">Saved Matches</h3>
      
      {/* Sorting Dropdown */}
      {savedMatches.length>0 && <div className="mb-4">
        <label htmlFor="sortOption" className="mr-2">Sort by:</label>
        <select
          id="sortOption"
          value={sortOption}
          onChange={handleSortChange}
          className="p-2 border rounded"
        >
          <option value="average">Average (Route & Time)</option>
          <option value="route">Route Match</option>
          <option value="time">Time Match</option>
        </select>
      </div>}

      {sortedMatches.length > 0 ? (
        <ul className="space-y-4">
          {sortedMatches.map((match) => (
            <SearchResultItem key={match.tripDetails.id} match={match} tripData={tripData} removeMatch={removeMatch} /> // Render individual match items
          ))}
        </ul>
      ) : (
        <p>No saved matches for this trip.</p>
      )}
    </div>
  );
};

export default SavedMatches;
