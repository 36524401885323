import React, { useState } from 'react';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

const PasswordField = ({ password, value, onChange, disabled, placeholder }) => {
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="mb-4 relative">
      <input
        type={showPassword ? 'text' : 'password'}
        name={password}
        id={password}
        value={value}
        onChange={onChange}
        className="input input-bordered w-full"
        placeholder={placeholder || 'Password'}
        required
        disabled={disabled} // Disable input when signing in
      />
      {/* Eye icon to toggle password visibility */}
      <span
        className={`absolute inset-y-0 right-3 flex items-center cursor-pointer ${disabled ? 'opacity-50' : ''}`}
        onClick={!disabled ? togglePasswordVisibility : null} // Disable toggle when signing in
      >
        {showPassword ? (
          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
        ) : (
          <EyeIcon className="h-5 w-5 text-gray-500" />
        )}
      </span>
    </div>
  );
};

export default PasswordField;
