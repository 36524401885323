import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const SaveCancelButtons = ({ onCancelUrl, disabled, init }) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(onCancelUrl);
  };

  return (
    <div className={"flex "+(init?"justify-center":"justify-between")}>
        <Link
        to="/my-profile"
      >
      {!init && <button
        type="button"
        onClick={handleCancel}
        className="btn btn-outline"
        disabled={disabled} // Disable cancel button during loading
      >
        Cancel
      </button>}
      </Link>
      <button
        type="submit"
        className="btn btn-primary"
        disabled={disabled} // Disable save button during loading
      >
        {disabled ? 'Saving...' : 'Save'}
      </button>
    </div>
  );
};

export default SaveCancelButtons;
