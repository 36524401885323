import React from 'react';
import { Link } from 'react-router-dom';

const BlogSection = () => (
  <div className="bg-gray-50 py-24">
    <div className="max-w-7xl mx-auto px-4 text-center">
      <h2 className="text-4xl font-bold mb-6">Visit Our Blog</h2>
      <p className="text-lg text-gray-700 mb-4">
        Stay updated with the latest news, ride-sharing tips, and travel stories from our community. Check out our blog to get inspired for your next adventure.
      </p>
      <Link to="/blog">
        <button className="btn btn-primary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
          Explore Our Blog
        </button>
      </Link>
    </div>
  </div>
);

export default BlogSection;
