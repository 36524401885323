import React, { useState } from 'react';
import ProfileSection from './components/ProfileSection';
import MapComponent from './components/MapComponent';
import TripDetails from '../TripDetails/TripDetails';
import ActionButtons from './components/ActionButtons';
import { FaChevronDown } from 'react-icons/fa'; // Import the Chevron icon from react-icons

const SearchResultItem = ({ match, tripData, removeMatch, expand }) => {        
    const [isExpanded, setIsExpanded] = useState(expand);
  
    const handleToggleExpand = () => {
        if(!expand) setIsExpanded(!isExpanded);
    };
  
    const { tripDetails } = match;
  
    return (
      <li className="flex flex-col mb-6 border rounded-lg shadow-sm relative">
        {/* Profile Section - Passing match info */}
        <ProfileSection match={match} handleToggleExpand={handleToggleExpand} expand={expand} />

        {/* Chevron Arrow Icon - Positioned just before the expanded content */}
        <div 
          className="flex justify-center items-center py-2 cursor-pointer"
          onClick={handleToggleExpand}
        >
          {/* Rotate the chevron based on expansion state */}
          <FaChevronDown 
            className={`transition-transform duration-300 ${isExpanded ? 'rotate-180' : ''}`} 
          />
        </div>

        {/* Animated Expanded Section */}
        <div
          className={`transition-max-height duration-500 ease-in-out overflow-hidden ${isExpanded ? 'max-h-[1000px]' : 'max-h-0'}`}
        >
          {/* Expanded Content */}
          <div>
            {/* Action Buttons - Using matchId from tripDetails.uid */}
            <ActionButtons match={match} tripData={tripData} removeMatch={removeMatch}/>
            
            {/* TripDetails Component - Passing match trip details */}
            <TripDetails matchData={tripDetails} ownData={tripData} />
  
            {/* Map Component - Displaying match details */}
            <MapComponent matchDetails={tripDetails} tripData={tripData} />
          </div>
        </div>
      </li>
    );
  };
  
  export default SearchResultItem;
