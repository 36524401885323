import Config from './assets/Config';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth'; // Firebase Auth
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Firestore imports
import { NotificationsProvider } from './components/NotificationsProvider';
import ScrollToTop from './components/ScrollToTop';
import Land from './pages/Land/Land';
import Home from './pages/Home/Home';
import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import EmailVerification from './pages/auth/EmailVerification';
import Blog from './pages/Blog/Blog';
import Faq from './pages/Faq/Faq';
import Post from './pages/Blog/Post';
import MyTrips from './pages/MyTrips/MyTrips';
import TripCreate from './pages/TripCreate/TripCreate';
import TripDetails from './pages/TripDetails/TripDetails';
import SearchResults from './pages/SearchResults/SearchResults';
import MatchView from './pages/MatchView/MatchView';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import EditProfilePage from './pages/EditProfilePage/EditProfilePage';
import Settings from './pages/Settings/Settings';
import ReviewPage from './pages/ReviewPage/ReviewPage';
import ChatList from './pages/chat/ChatList';
import ChatRoom from './pages/chat/ChatRoom';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';
import CookiePolicy from './pages/CookiePolicy/CookiePolicy';
import Header from './components/Header';
import Footer from './components/Footer';
import ContactForm from "./pages/contact/ContactForm";
import ResetPassword from "./pages/auth/ResetPassword"
import TermsForm from './pages/auth/TermsForm'
import AboutUs from "./pages/AboutUs/AboutUs";
import Loader from './components/Loader';
import logo from 'assets/images/logo.png';


const FooterRoutes = [
        <Route key='terms' path="/terms" element={<Terms />} />,
        <Route key='privacy' path="/privacy-policy" element={<Privacy />} />,
        <Route key='cookie' path="/cookie-policy" element={<CookiePolicy />} />,
        <Route key='contact' path="/contact" element={<ContactForm />} />,
        <Route key='about' path="/about" element={<AboutUs />} />
]
const Logo = ({width})=>{
    return(
        <Link to='/' className='cursor-pointer'>
            <img src={logo} alt="Logo" className='m-5' style={{ width: (width?width:"300")+"px", height: "auto" }} />
        </Link>
    )
}
const Loading = ()=>{
    return(
        <div className='min-h-screen flex flex-col items-center justify-center'>
            <img src={logo} alt="Logo" className='m-5' style={{ width: "300px", height: "auto" }} />
            <Loader margin="50" />
        </div>
    )
}
const Reset = ()=>{
    return(
        <Router>
            <ScrollToTop />
            <div className="relative min-h-screen flex flex-col bg-gray-100">
                <div className='flex min-h-screen flex-col justify-center items-center'>
                    <Logo/>
                    <Routes>
                        <Route path="/reset-password" element={<ResetPassword/>} />
                        {FooterRoutes}
                    </Routes>
                </div>
                <Footer/>
            </div>
        </Router>
    )
}
const Verif = ()=>{
    return(
        <Router>
            <ScrollToTop />
            <div className="relative min-h-screen flex flex-col bg-gray-100">
                <div className='flex min-h-screen flex-col justify-center items-center'>
                    <Logo/>
                    <Routes>
                        <Route path="/" element={<EmailVerification/>} />
                        <Route path="/verify-email" element={<EmailVerification/>} />
                        {FooterRoutes}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
                <Footer/>
            </div>
        </Router>
    )
}
const Start = ({ssr})=>{
    return(
        <Router>
            <ScrollToTop />
            <div className="relative min-h-screen flex flex-col bg-gray-100">
            <Header isLoggedIn={false} hasUnreadMessages={true} />
            <div className="flex-1 flex flex-col h-full">
            <Routes>
                <Route path="/" element={<Land ssr={ssr} />} />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog/:postSlug" element={<Post />} />
                {FooterRoutes}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            </div>
            <Footer />
            </div>
        </Router>
    )
}
const Intake = ()=>{
    return(
        <Router>
            <ScrollToTop />
            <div className="relative min-h-screen flex flex-col bg-gray-100">
                <div className='flex min-h-screen flex-col justify-center items-center'>
                    <Logo/>
                    <Routes>
                        <Route path="/" element={<EditProfilePage init={true}/>} />
                        {FooterRoutes}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    )
}
const TermsPage = ()=>{
    return(
        <Router>
            <ScrollToTop />
            <div className="relative min-h-screen flex flex-col bg-gray-100">
                <div className='flex flex-col justify-center items-center'>
                    <Logo width={200}/>
                    <Routes>
                        <Route path="/" element={<TermsForm/>} />
                        {FooterRoutes}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </div>
                <Footer />
            </div>
        </Router>
    )
}
const App = ()=>{
    return(
        <NotificationsProvider>
            <Router>
                <ScrollToTop />
                <div className="relative min-h-screen flex flex-col bg-gray-100">
                    <Header isLoggedIn={true} hasUnreadMessages={true} />
                    <div className="flex-1 flex flex-col h-full">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/my-profile" element={<ProfilePage />} />
                        <Route path="/my-profile/:reviewID" element={<ProfilePage />} />
                        <Route path="/edit-profile" element={<EditProfilePage />} />
                        <Route path="/settings" element={<Settings />} />
                        <Route path="/profile/:id" element={<ProfilePage />} />
                        <Route path="/my-trips" element={<MyTrips />} />
                        <Route path="/search-results/:tripId" element={<SearchResults />} />
                        <Route path="/create-trip" element={<TripCreate />} />
                        <Route path="/match/:tripID/:matchID" element={<MatchView />} />
                        <Route path="/trip-details/:tripId" element={<TripDetails />} />
                        <Route path="/chats/:chatId" element={<ChatRoom />} />
                        <Route path="/chats/user/:partnerUID" element={<ChatRoom />} />
                        <Route path="/chats" element={<ChatList />} />
                        <Route path="/review/:userId" element={<ReviewPage />} />
                        {FooterRoutes}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                    </div>
                    <Footer />
                </div>
            </Router>
        </NotificationsProvider>
    )
}

const Main = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [redirectToEditProfile, setRedirectToEditProfile] = useState(false); // New state
    
    const checkUserProfile = async (user) => {
        const db = getFirestore(); // Initialize Firestore
        const userDocRef = doc(db, 'users', user.uid);
        
        try {
            const userDoc = await getDoc(userDocRef);
            if(userDoc.exists()){
                setRedirectToEditProfile(!userDoc.data().init);
                setTermsAccepted(userDoc.data().termsAccepted);
            }
            else setRedirectToEditProfile(true);
        } catch (err) {
            console.error("Error fetching user document:", err);
        }
    };

    const init = async () => {
        await Config.init(); // Initialize your config    
        const auth = getAuth(); // Initialize Firebase auth
        
        // Listen to the user's authentication state
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                console.log("uid: "+user.uid);
                
                // Check email verification            
                setIsEmailVerified(user.emailVerified);
                if(user.emailVerified) await checkUserProfile(user); // Check if the user profile exists
                setIsLoggedIn(true); // User is logged in
            } else {
                setIsLoggedIn(false); // User is logged out
            }
            setLoading(false); // Stop loading once the auth state is determined
        });
    };

    useEffect(() => {
        init();
    }, []);

    if (loading) return <Loading/>
    else if(window.location.pathname === '/reset-password')return <Reset />
    else if(!isLoggedIn) return <Start/>
    else if (isLoggedIn && !isEmailVerified) return <Verif />;
    else if (redirectToEditProfile) return <Intake/>
    else if(!termsAccepted) return <TermsPage/>
    else return <App/>
}

export default Main;
