import React from 'react';

const SubmitButton = ({ buttonText, disabled }) => {
  return (
    <div className="flex justify-center">
      <button type="submit" className="btn btn-primary w-full" disabled={disabled}>
        {disabled?"Creating Trip...":buttonText}
      </button>
    </div>
  );
};

export default SubmitButton;
