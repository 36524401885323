import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import keys from './keys.json';

const sleep = (sec) => {
    return new Promise(resolve => setTimeout(resolve, sec * 1000));
};
const isLocal = ()=>{
    return window.location.hostname.includes("localhost");
}
const isDev = ()=>{
    return (window.location.hostname.includes("dev") || window.location.hostname.includes('192.168.215.103'));
}
const initGtag = ()=>{
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5HQVZ9BS')
}
const init = async () => {
    try {
        // load gtag
        if(!isLocal() && !isDev()){
            initGtag();
            console.log('GTM-5HQVZ9BS gtag initialized.');
        }

        
        // Load Google Maps script
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=` + keys.maps + `&libraries=places`; // URL of the external script
        script.async = true;
        document.body.appendChild(script);

        // Wait for Google Maps API to load
        while (!window.google) await sleep(0.5);

        // Check if running locally and connect to the emulators
        if (window.location.hostname === "localhost") {
            connectFunctionsEmulator(getFunctions(), "localhost", 3002); // Default port for the Firebase Functions emulator
            console.log("Connected to Firebase Functions Emulator");
            // connectFirestoreEmulator(getFirestore(), 'localhost', 3003);
            // console.log("Connected to Firestore emulator");
        }

        // Return
        return Promise.resolve();
    } catch (err) {
        return Promise.reject(err);
    }
};

// Initialize Firebase
const domainName = "ride-share.app"
const appKeys = (isDev() || isLocal())?keys.firebase.dev:keys.firebase.prod;
console.log(appKeys.projectId);
appKeys.authDomain = domainName;
if(isDev() || isLocal()) appKeys.authDomain = "dev."+domainName;


const firebaseApp = initializeApp(appKeys);
const Config = {
    domain: isLocal()?"http://localhost:3000/":"https://"+(isDev() && "dev.")+domainName,
    keys: keys,
    init: init
};

export default Config;
