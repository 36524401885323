import React, { useEffect, useRef, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const ProfilePicture = ({ userId, handleImageClick, count, small }) => {
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const className = "relative flex flex-col justify-center items-center "+(small?"w-10":"w-24")+" aspect-square rounded-full bg-blue-500 mr-4 "+(handleImageClick?"cursor-pointer":"")
    const [loading, setLoading] = useState();
    const imgRef = useRef();
    
    useEffect(() => {
        const fetchProfileImage = async () => {
            setLoading(true);
            try {
                const storage = getStorage();
                const imageRef = ref(storage, `users/${userId}/profile.jpg`);
                const url = await getDownloadURL(imageRef); // Fetch the profile image
                setProfileImageUrl(url); // Set the fetched image URL
            } catch (error) {
                console.error(error);
                setLoading(false);
                // If the image doesn't exist, handle error by showing a default
            }
        };
        setProfileImageUrl(null);
        if (userId) fetchProfileImage();
    }, [count]);
    
    return (
        <>
        {profileImageUrl ? (
            <div className={className}>
                <img
                    ref={imgRef}
                    src={profileImageUrl}
                    alt="Profile"
                    className='rounded-full'
                    onClick={handleImageClick}
                    onLoad={()=>{
                        setLoading(false);
                    }}
                />
                {loading && <div className="absolute w-[50%] h-[50%] text-white loading"/>}
            </div>
        ) : (
            <div
                className={className}
                onClick={handleImageClick}
            >
                {loading && <div className="absolute w-[50%] h-[50%] text-white loading"/>}
            </div>
        )}
        </>
    )
};

export default ProfilePicture;
