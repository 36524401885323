import React, { useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const verifyOtpAndUpdateEmail = httpsCallable(getFunctions(), 'verifyOTPAndUpdateEmail');

const OtpInput = ({ setLoading, setError, setSuccessMessage, loading, onClose }) => {
  const otpRef = useRef(null);

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const otp = otpRef.current.value; // Get OTP value from ref
      const result = await verifyOtpAndUpdateEmail({ otp });
      console.log(result.data.message); // "Email updated successfully"
      setSuccessMessage('Your email has been updated successfully!');
    } catch (error) {
      console.error("Error verifying OTP:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleVerifyOtp} className="space-y-4">
      <h2 className="text-xl font-semibold mb-4">Enter OTP</h2>
      <div>
        <h3 className="text-base font-semibold mb-2">OTP</h3>
        <input
          ref={otpRef}
          type="text"
          name="otp"
          placeholder="Enter OTP"
          className="w-full p-2 border border-gray-300 rounded-md"
          disabled={loading}
        />
      </div>
      <div className='flex justify-between'>
      <button
                type="button"
                onClick={onClose}
                className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition-colors"
                disabled={loading}
              >
                Cancel
              </button>
      <button
        type="submit"
        className={`bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors ${loading ? 'opacity-50' : ''}`}
        disabled={loading}
      >
        {loading ? 'Processing...' : 'Verify OTP'}
      </button>
      </div>
    </form>
  );
};

export default OtpInput;
