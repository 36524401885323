import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Title and Intro Section */}
        <h1 className="text-5xl font-extrabold text-blue-600 text-center">
          The Eco-Friendly Benefits of Ride Sharing
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Ride sharing offers significant eco-friendly benefits that help reduce pollution, conserve resources, and promote sustainable travel. Discover how sharing rides can make a difference for the planet.
        </p>

        {/* Section 1: Lower Carbon Emissions */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            1. Lower Carbon Emissions
          </h2>
          <p className="text-lg text-gray-700">
            One of the most significant environmental benefits of <strong>ride sharing</strong> is the reduction in carbon emissions. With fewer cars on the road, fuel consumption decreases, resulting in lower emissions.
          </p>
          <p className="text-lg text-gray-700">
            According to the EPA, a typical passenger vehicle emits about 4.6 metric tons of carbon dioxide per year. By choosing <strong>ride sharing</strong>, you’re contributing to lower overall carbon emissions, particularly on <strong>long-distance trips</strong>.
          </p>
        </section>

        {/* Section 2: Reduced Traffic Congestion */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            2. Reduced Traffic Congestion
          </h2>
          <p className="text-lg text-gray-700">
            Traffic congestion leads to unnecessary idling, which increases fuel consumption and pollution. By sharing rides, fewer cars are needed to transport the same number of people, reducing traffic and emissions.
          </p>
          <p className="text-lg text-gray-700">
            Ride sharing helps cut down on congestion, making travel more efficient and environmentally friendly, especially in busy urban areas.
          </p>
        </section>

        {/* Section 3: Conservation of Resources */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            3. Conservation of Resources
          </h2>
          <p className="text-lg text-gray-700">
            Producing cars requires a tremendous amount of resources like steel, rubber, and plastic. By reducing the number of vehicles needed through <strong>ride sharing</strong>, we conserve the resources used in manufacturing and maintenance.
          </p>
          <p className="text-lg text-gray-700">
            Sharing rides leads to fewer cars on the road, reducing the energy required to produce and maintain vehicles.
          </p>
        </section>

        {/* Section 4: Minimized Fuel Consumption */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            4. Minimized Fuel Consumption
          </h2>
          <p className="text-lg text-gray-700">
            With fewer vehicles needed to transport passengers, <strong>ride sharing</strong> also minimizes fuel consumption. This is particularly impactful for <strong>long-distance rides</strong>, where carpooling significantly reduces fuel use.
          </p>
          <p className="text-lg text-gray-700">
            Sharing rides is not only cost-effective but also a sustainable alternative to solo driving.
          </p>
        </section>

        {/* Section 5: Promoting a Sustainable Lifestyle */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            5. Promoting a Sustainable Lifestyle
          </h2>
          <p className="text-lg text-gray-700">
            Beyond the direct environmental benefits, <strong>ride sharing</strong> promotes a sustainable lifestyle. By choosing to share rides instead of driving alone, you’re setting an example for others and contributing to a culture of sustainability.
          </p>
          <p className="text-lg text-gray-700">
            Encouraging more people to adopt <strong>ride sharing</strong> can have a broader impact on reducing emissions and promoting eco-conscious behavior.
          </p>
        </section>

        {/* Conclusion Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Conclusion
          </h2>
          <p className="text-lg text-gray-700">
            **Ride sharing** is much more than a convenient way to travel—it’s a powerful tool for reducing your environmental footprint. From cutting carbon emissions to conserving resources, **ride sharing** offers a range of **eco-friendly benefits** that contribute to a greener future.
          </p>
          <p className="text-lg text-gray-700">
            Ready to make your travel greener? <Link to="/register" className="text-blue-500 font-bold">Join Ride Share</Link> today and start sharing rides for a more sustainable tomorrow!
          </p>
        </section>

        {/* Call to Action */}
        <div className="text-center py-8">
          <Link to="/register">
            <button className="btn btn-primary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
              Join Ride Share Now!
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
