import React from 'react';
import { Link } from 'react-router-dom';
import { faqs } from './FAQData';

const FAQ = () => {
  return (
    <div className="bg-gray-50 py-24">
      <div className="max-w-4xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-blue-700 mb-12">Frequently Asked Questions</h2>
        <div className="space-y-8">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 md:p-8"
            >
              <h3 className="text-2xl font-semibold text-blue-600 mb-3">{faq.question}</h3>
              <p className="text-lg text-gray-700 leading-relaxed">
                {faq.answer}
              </p>
            </div>
          ))}
        </div>
        {/* Call-to-Action Section */}
        <div className="mt-16 text-center">
          <h3 className="text-3xl font-bold text-blue-700 mb-6">Ready to start carpooling?</h3>
          <p className="text-lg text-gray-700 mb-8">
            Join thousands of travelers finding eco-friendly, affordable travel solutions on Ride Share. Post your trip or find a ride now!
          </p>
          <Link
            to="/start"
            className="inline-block bg-blue-700 text-white px-8 py-4 rounded-lg text-lg hover:bg-blue-800 transition duration-300"
          >
            Sign Up Now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
