import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Firestore imports
import { getAuth } from 'firebase/auth'; // Firebase auth import
import ProfileDetails from './components/ProfileDetails';
import UserReviews from './components/UserReviews';
import Loader from '../../components/Loader';

const ProfilePage = () => {
  const { id, reviewID } = useParams(); // Get the ID from the route parameters
  const [uid, setUid] = useState(id);
  const [userProfile, setUserProfile] = useState(null); // State to store the fetched profile data
  const [loading, setLoading] = useState(true); // Loading state
  const db = getFirestore(); // Initialize Firestore
  const auth = getAuth();
  const currentUser = auth.currentUser; // Get the current authenticated user

  useEffect(() => {
    const fetchUserProfile = async () => {
      setLoading(true);
      try {
        let userDocRef;
        if (id && id!=currentUser.uid){
            userDocRef = doc(db, 'users', id);
            setUid(id);
        }
        else{
            userDocRef = doc(db, 'users', currentUser.uid);
            setUid(currentUser.uid);
        }
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUserProfile({
            ...userData,
            email: (!id || id==currentUser.uid) && auth.currentUser.email,
            id: id?id:auth.currentUser.uid
            });
        } else {
          console.error('No user profile found');
          setUserProfile(null); // No profile found
        }
      } catch (err) {
        console.error('Error fetching profile:', err);
        setUserProfile(null);
      } finally {
        setLoading(false); // Stop loading once the data is fetched
      }
    };

    if (currentUser) {
      fetchUserProfile();
    } else {
      setLoading(false); // Stop loading if no current user (e.g., not logged in)
    }
  }, [id, currentUser, db]);

  if (loading) return <Loader /> // Use Loader component while data is being fetched

  if (!userProfile) return <div>No profile found.</div> // Handle the case where no profile data is found
  
  return (
    <div className="p-8">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-md">
        <ProfileDetails userProfile={userProfile}/>
        <UserReviews uid={uid} reviewID={reviewID} />
      </div>
    </div>
  );
};

export default ProfilePage;
