import React, { useState, useEffect } from 'react';
import { getAuth, signOut, applyActionCode } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useSearchParams } from 'react-router-dom';

const sendEmailVerification = httpsCallable(getFunctions(), 'sendEmailVerification');

const EmailVerification = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState();
  const [loadingOobCode, setLoadingOobCode] = useState(true); // Loading state for oobCode verification
  const [loadingEmail, setLoadingEmail] = useState(false); // Loading state for email sending
  const [searchParams] = useSearchParams();

  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const code = searchParams.get('oobCode');
    if (code) {
      verifyEmail(code);
    } else {
      setLoadingOobCode(false);
    }
  }, [searchParams]);

  const verifyEmail = async (code) => {
    setError(null);
    try {
      await applyActionCode(auth, code);
      window.open('/','_self'); // Redirect to home after successful verification
    } catch (error) {
      setError('The verification link is invalid or expired.');
      setLoadingOobCode(false);
    }
  };

  const handleResendVerification = async () => {
    setError('');
    setLoadingEmail(true);
    try {
      await sendEmailVerification();
      setEmailSent(true);
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setLoadingEmail(false);
    }
  };

  const handleLogout = async () => {
    setLoadingEmail(true);
    try {
      await signOut(auth);
      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      setLoadingEmail(false);
    }
  };

  // If we are verifying the oobCode, show loading
  if (loadingOobCode) {
    return <p>Verifying your email...</p>;
  }

  return (
    <div className="flex flex-col items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md text-center">
        {error && <div className='alert alert-error'>{error}</div>}
      <p className="mb-6">
                  We have sent a verification link to <strong>{user.email}</strong>.<br/>
                  Please verify your email address to continue using our services.
                </p>
                {emailSent ? (
                  <p className="text-green-500 mb-4">Verification email sent! Please check your inbox.</p>
                ) : (
                  <button
                    onClick={handleResendVerification}
                    className={`bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 ${loadingEmail ? 'cursor-not-allowed opacity-50' : ''}`}
                    disabled={loadingEmail}
                  >
                    {loadingEmail ? 'Sending...' : 'Resend Verification Email'}
                  </button>
                )}
                
      </div>
    </div>
  );
};

export default EmailVerification;
