import React, { useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';

const sendOtp = httpsCallable(getFunctions(), 'sendOTP');

const EmailInput = ({ user, setOtpSent, setLoading, setError, loading, onClose }) => {
  const emailRef = useRef(null);

  const handleSendConfirmation = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    if (!user) {
      setError('No user is currently logged in.');
      setLoading(false);
      return;
    }

    try {
      const email = emailRef.current.value; // Get email value from ref
      const result = await sendOtp({ newEmail: email });
      console.log(result.data.message); // "OTP sent to new email"
      setOtpSent(true); // Proceed to OTP input stage
    } catch (error) {
      console.error("Error sending OTP:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSendConfirmation} className="space-y-4">
      <h2 className="text-xl font-semibold mb-4">Enter New Email</h2>
      <div>
        <h3 className="text-base font-semibold mb-2">New Email</h3>
        <input
          ref={emailRef}
          type="email"
          name="email"
          placeholder="New Email"
          className="w-full p-2 border border-gray-300 rounded-md"
          disabled={loading}
        />
      </div>
      <div className='flex justify-between'>
      <button
                type="button"
                onClick={onClose}
                className="bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition-colors"
                disabled={loading}
              >
                Cancel
              </button>
      <button
        type="submit"
        className={`bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors ${loading ? 'opacity-50' : ''}`}
        disabled={loading}
      >
        {loading ? 'Processing...' : 'Send OTP'}
      </button>
      </div>
    </form>
  );
};

export default EmailInput;
