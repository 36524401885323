import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore'; 
import InputField from './components/InputField';
import TextareaField from './components/TextareaField';
import ProfileImageUpload from './components/ProfileImageUpload';
import CountrySelect from './components/CountrySelect';
import ForgotPassword from "../auth/components/ForgotPassword";
import ChangeEmailOverlay from './components/ChangeEmailOverlay';
import SaveCancelButtons from './components/SaveCancelButtons';
import Loader from '../../components/Loader'; // Importing the Loader component

const EditProfilePage = ({ init }) => {    
  const auth = getAuth();
  const db = getFirestore();
  const user = auth.currentUser;
  const navigate = useNavigate(); 

  // Create refs for form fields
  const nameRef = useRef();
  const descriptionRef = useRef();

  // State for controlling overlays and loading
  const [isEmailOverlayOpen, setIsEmailOverlayOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [profileData, setProfileData] = useState({
    name:'',
    description:''
  });
  const [selectedCountry, setSelectedCountry] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setProfileData({
            ...userData,
            email: user.email,
          });
          setSelectedCountry(userData.country || '');
        } else {
          console.error('No user profile found');
        }
      } catch (err) {
        console.error('Error fetching profile:', err);
      } finally {
        setLoading(false);
      }
    };
    if(init) setLoading(false);
    else if (user) {
      fetchUserProfile();
    }
  }, [user, db]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmiting(true);

    const updatedData = {
        init: true,
        name: nameRef.current.value,
        country: selectedCountry,
        description: descriptionRef.current.value,
    };

    try {
      const docRef = doc(db, 'users', user.uid);
      await setDoc(docRef, updatedData, { merge: true });
      if (init) window.open('/my-profile', "_self");
      else navigate('/my-profile');
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
        setSubmiting(false);
    }
  };

  if (loading) return <Loader />; // Use the Loader component when loading

  return (
    <div className="max-w-[600px] w-full mx-auto p-8 rounded-lg shadow-md bg-white space-y-6 m-8">
        <ProfileImageUpload userId={user.uid} disabled={loading} />
        {/* Email Display and Change Button */}
        {!init && (
          <div className="flex flex-col items-start space-y-2">
            <div className="text-base text-gray-600">{profileData.email}</div>
            <button
              type="button"
              onClick={() => setIsEmailOverlayOpen(true)}
              className="text-blue-500 hover:underline m-0"
              disabled={submiting}
            >
              Change Email
            </button>
            <ForgotPassword change={true} />
          </div>
        )}
      <form onSubmit={handleSubmit} className="space-y-6">
        <InputField
          label="Name"
          type="text"
          name="name"
          ref={nameRef}
          defaultValue={profileData.name}
          required
          disabled={submiting}
        />

        <CountrySelect
          defaultValue={selectedCountry}
          onChange={setSelectedCountry}
          disabled={submiting}
        />

        <TextareaField
          label="Description"
          name="description"
          ref={descriptionRef}
          defaultValue={profileData.description}
          disabled={submiting}
        />

        <SaveCancelButtons
          init={init}
          onSubmit={handleSubmit}
          onCancelUrl="/my-profile"
          disabled={submiting}
        />
      </form>

      {isEmailOverlayOpen && <ChangeEmailOverlay onClose={() => setIsEmailOverlayOpen(false)} />}
    </div>
  );
};

export default EditProfilePage;
