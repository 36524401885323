// NotificationItem.js
import {useState} from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const colors={
    match:"bg-lime-300",
    chat:"bg-orange-300",
    review:"bg-blue-300"
}
const NotificationItem = ({ notification }) => {  
    const nav = useNavigate();
    const [loading, setLoading] = useState()
    const auth = getAuth();
    const db = getFirestore();
    const currentUser = auth.currentUser;
    
    const markAsRead = async(notificationId) => {
        setLoading(true);
        try{     
            if(currentUser){
                const notificationRef = doc(db, 'users', currentUser.uid, 'notifications', notificationId);
                await deleteDoc(notificationRef);
            }
        }
        catch(err){
            console.error(err);      
        }
        setLoading(false);
    }

    return (
        <div className={'alert alert-primary flex justify-between text-black '+colors[notification.type]}>
            <div className='cursor-pointer w-[80%]' onClick={()=>{nav(notification.link)}}>
                <p className='font-semibold'>{notification.content}</p>
                <span className="text-xs">
                    {new Date(notification.timestamp.seconds * 1000).toLocaleString()}
                </span>
            </div>

            <button
                    className="btn btn-xs btn-outline ml-2"
                    onClick={() => markAsRead(notification.id)}
                    disabled={loading}
            >
                    {loading?"Marking...":"Mark as Read"}
            </button>
        </div>
    )
};

export default NotificationItem;