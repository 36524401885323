import React from 'react';

const RadiusInput = ({ radius, setRadius }) => {
  // Handle radius selection
  const handleRadiusChange = (e) => {
    setRadius(Number(e.target.value));
  };

  return (
    <div className="relative flex flex-col justify-center mb-4 w-[30%]">
      {/* Select element for radius selection */}
      <select
        value={radius}
        onChange={handleRadiusChange}
        className="select select-bordered w-full"
        required
      >
        <option value="" disabled>
          Area in KM
        </option>
        {Array.from({ length: 100 }, (_, i) => (
          <option key={i + 1} value={i + 1}>
            {i + 1} KM
          </option>
        ))}
      </select>
    </div>
  );
};

export default RadiusInput;
