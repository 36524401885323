import { doc, getDoc } from 'firebase/firestore';

const fetchPartnerName = async (partnerUID, db) => {
  const userDocRef = doc(db, 'users', partnerUID);
  try {
    const userDoc = await getDoc(userDocRef);
    return userDoc.exists() ? userDoc.data().name : 'Unknown User';
  } catch (error) {
    console.error("Error fetching partner's name:", error);
    return 'Unknown User';
  }
};

export default fetchPartnerName;
