import React from 'react';

const Loader = ({margin}) => {    
  return (
    <div className={"m-["+(margin?margin:"100")+"px] flex justify-center items-center"}>
      <div className="relative w-16 h-16 loading"/>
    </div>
  )
}

export default Loader;
