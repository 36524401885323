import React from 'react';
import { Link } from 'react-router-dom';

const TextSection = () => (
  <div className="bg-gray-50 py-24">
    <div className="max-w-7xl mx-auto px-4 text-center">
      <h2 className="text-3xl md:text-4xl font-bold mb-6">Plan Your Ride With Ease</h2>
      <p className="text-lg md:text-xl mb-4 text-gray-700">
        Whether it's a weekend getaway or a cross-country adventure, Ride Share helps you connect with other travelers going your way. Share rides, split costs, and reduce your environmental impact. Sign up now to join our carpool network and find your next ride.
      </p>
      <Link to="/register">
        <button className="btn btn-primary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
          Sign Up for Free – Start Saving!
        </button>
      </Link>
    </div>
  </div>
);

export default TextSection;
