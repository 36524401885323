import React, { useState } from 'react';
import ImageUploader from './ImageUploader';
import ImageCropper from './ImageCropper';

const ProfileImageUpload = ({ disabled, userId }) => {
  const [newImage, setNewImage] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [count, setCount] = useState(0);

  // Handle image selection from the file input
  const handleImageSelect = (image) => {
    setNewImage(image);
    setShowCropper(true); // Show the cropper
  };

  // Handle crop completion
  const handleCropComplete = async () => {
    setShowCropper(false); // Hide the cropper
    setCount(count+1);
  };

  return (
    <div className="mb-6 text-center">
      <ImageUploader
        userId={userId}
        onImageSelect={handleImageSelect}
        disabled={disabled}
        count={count}
      />
      {showCropper && (
        <ImageCropper
          image={newImage}
          onCropComplete={handleCropComplete}
          onCancel={() => setShowCropper(false)}
          croppedAreaPixels={croppedAreaPixels}
          setCroppedAreaPixels={setCroppedAreaPixels}
        />
      )}
    </div>
  );
};

export default ProfileImageUpload;
