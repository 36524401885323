import React from "react";

const StarRating = ({ averageRating, size = 24 }) => {
  const MAX_STARS = 5;

  // Function to render a star with the correct fill level
  const renderStar = (index) => {
    const fullStar = (
      <svg
        key={index}
        className="text-yellow-500"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        style={{ width: size, height: size }}
      >
        <path d="M12 .587l3.668 7.431L24 9.743l-6 5.854 1.42 8.294L12 18.896l-7.42 3.995L6 15.597 0 9.743l8.332-1.725L12 .587z" />
      </svg>
    );

    const emptyStar = (
      <svg
        key={index}
        className="text-gray-300"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        style={{ width: size, height: size }}
      >
        <path d="M12 .587l3.668 7.431L24 9.743l-6 5.854 1.42 8.294L12 18.896l-7.42 3.995L6 15.597 0 9.743l8.332-1.725L12 .587z" />
      </svg>
    );

    const fraction = averageRating - index; // Fractional part of the current star

    if (fraction > 0 && fraction < 1) {
      const clipPercentage = fraction * 100; // Dynamic percentage for clipping
      return (
        <div key={index} className="relative" style={{ width: size, height: size }}>
          <svg
            className="absolute top-0 left-0 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            style={{ width: size, height: size }}
          >
            <path d="M12 .587l3.668 7.431L24 9.743l-6 5.854 1.42 8.294L12 18.896l-7.42 3.995L6 15.597 0 9.743l8.332-1.725L12 .587z" />
          </svg>
          <svg
            className="absolute top-0 left-0 text-yellow-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            style={{
              width: size,
              height: size,
              clipPath: `inset(0 ${100 - clipPercentage}% 0 0)`, // Dynamic clipping
            }}
          >
            <path d="M12 .587l3.668 7.431L24 9.743l-6 5.854 1.42 8.294L12 18.896l-7.42 3.995L6 15.597 0 9.743l8.332-1.725L12 .587z" />
          </svg>
        </div>
      );
    }

    return averageRating >= index + 1 ? fullStar : emptyStar;
  };

  return (
    <div className="flex items-center">
      {Array.from({ length: MAX_STARS }, (_, index) => renderStar(index))}
    </div>
  );
};

export default StarRating;
