import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, getDoc, setDoc, query, getDocs, collection, where, addDoc, doc } from 'firebase/firestore';
import fetchPartnerName from './components/fetchPartnerName';
import ChatHeader from './components/ChatHeader';
import MessageList from './components/MessageList';
import MessageInput from './components/MessageInput';
import Loader from '../../components/Loader';

const ChatRoom = () => {
    const { chatId, partnerUID } = useParams();
    const db = getFirestore();
    const auth = getAuth();
    const currentUserId = auth.currentUser?.uid;
    const [loading, setLoading] = useState(true);
    const [currentChat, setCurrentChat] = useState();
    
  // Effect to handle chat subscription
  useEffect(() => {
    const init = async()=>{    
        try{
            const chatsRef = collection(db, 'chats');
            let chatObj;
            if(chatId){
                const chatRef = doc(chatsRef, chatId);
                const snapshot = await getDoc(chatRef);
                if(!snapshot.exists()) throw "chat doesn't exists.";
                const chatData = snapshot.data();
                const partnerUID = Object.keys(chatData.participants).find(uid => uid !== currentUserId);
                const partnerName = await fetchPartnerName(partnerUID, db);
                chatObj = { id: chatRef.id, partnerName, partnerUID, ...chatData }
            }
            else if(partnerUID){
                // check if partnerUID has a profile doc in /users/${partnerUID}.
                const partnerName = await fetchPartnerName(partnerUID, db);
                if (partnerName !== 'Unknown User') {
                    // check if a chat already exists
                    const chatsQuery = query(chatsRef,
                        where(`participants.${currentUserId}`, '==', true),
                        where(`participants.${partnerUID}`, '==', true),
                    );
                    const snapshot = await getDocs(chatsQuery);
                    if(snapshot.docs.length>0){
                        const chat = snapshot.docs[0];
                        chatObj = { id: chat.id, partnerName, partnerUID, ...chat.data() }
                    }
                    else{ // create new doc
                        const newChatRef = doc(chatsRef)
                        const participants = {}
                        participants[currentUserId] = true;
                        participants[partnerUID] = true;    
                        const newChatData = {
                            participants: participants,
                            createdAt: new Date(),
                        };
                        // Create the chat document
                        await setDoc(newChatRef, newChatData);
                        // Create a messages subcollection by adding an initial placeholder message
                        const messagesRef = collection(newChatRef, 'messages');
                        const initialMessage = {
                            init: true
                        }
                        await addDoc(messagesRef, initialMessage);
                        chatObj = { id: newChatRef.id, partnerName, partnerUID, ...newChatData }
                    }
                }
            }
            // Update state            
            setCurrentChat(chatObj);
        }
        catch(err){
            console.error(err);
        }
        setLoading(false);
    }
    init();
  }, []);

  if (loading) return <Loader />;
  if (!currentChat) return <div className="p-4 text-center">This chat does not exist.</div>;

  return (
    <div className="relative flex flex-col h-[600px]">
      <ChatHeader currentChat={currentChat} />
      <MessageList 
        currentUserId={currentUserId} 
        partnerName={currentChat?.partnerName} 
        currentChat={currentChat} 
      />
      <MessageInput 
        currentChat={currentChat} 
        currentUserId={currentUserId} 
        partnerUID={partnerUID} 
      />
    </div>
  );
};

export default ChatRoom;
