import React from 'react';

const Privacy = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto bg-base-100 shadow-lg rounded-lg m-5">
      <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
      <p className="mb-4">
        At <strong>Ride Share</strong> ("we," "our," or "us"), we value your privacy and are committed to protecting your personal information. 
        This Privacy Policy describes how we collect, use, store, and disclose your information when you use the <strong>Ride Share</strong> platform, website, mobile app, or other services (collectively, the "Service").
      </p>

      <h2 className="text-2xl font-semibold mb-2">1. Information We Collect</h2>
      <p className="mb-4">
        We collect different types of information depending on how you use our Service:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Personal Information:</strong> When you create an account, we collect information such as your name, email address, and phone number.
        </li>
        <li>
          <strong>Trip Information:</strong> When you post a trip, we collect details about your trip including start and end locations, times, and other relevant trip details.
        </li>
        <li>
          <strong>Location Information:</strong> With your permission, we may collect your precise location while using the app to match you with relevant trips.
        </li>
        <li>
          <strong>Usage Information:</strong> We collect data about how you interact with the Service, such as trip history, messages, reviews, and app activity.
        </li>
        <li>
          <strong>Device Information:</strong> We may collect information about your device, such as your IP address, operating system, browser type, and device ID.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">2. How We Use Your Information</h2>
      <p className="mb-4">
        We use the information we collect for various purposes, including:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>To create and manage your account.</li>
        <li>To facilitate trip matching and communication with other users.</li>
        <li>To improve and personalize your experience with the Service.</li>
        <li>To send you notifications and updates about your trips or our Service.</li>
        <li>To analyze usage trends and improve the functionality of the app.</li>
        <li>To comply with legal obligations and protect against fraud and abuse.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">3. Sharing Your Information</h2>
      <p className="mb-4">
        We will not sell your personal information to third parties. However, we may share your information in the following ways:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>With Other Users:</strong> Your profile information, trip details, and reviews are visible to other users. However, your email address and phone number are not shared unless you choose to share them directly.
        </li>
        <li>
          <strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating the Service, such as cloud storage providers or email service providers.
        </li>
        <li>
          <strong>Legal Obligations:</strong> We may share your information if required by law or to protect the safety, rights, or property of ourselves or others.
        </li>
        <li>
          <strong>Business Transfers:</strong> In the event of a merger, acquisition, or asset sale, your information may be transferred as part of the transaction.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">4. Data Security</h2>
      <p className="mb-4">
        We use industry-standard security measures to protect your information from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
      </p>

      <h2 className="text-2xl font-semibold mb-2">5. Your Choices and Rights</h2>
      <p className="mb-4">
        You have certain rights regarding the personal information we collect about you:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Access and Update:</strong> You can access and update your account information at any time through your profile settings.
        </li>
        <li>
          <strong>Delete:</strong> You can request that we delete your account and personal information by contacting us at <a href="mailto:contact@ride-share.app" className="link link-primary">contact@ride-share.app</a>. Please note that certain information may be retained to comply with legal obligations or to resolve disputes.
        </li>
        <li>
          <strong>Opt-Out:</strong> You can opt out of receiving marketing communications by following the unsubscribe instructions in the emails we send or by contacting us directly.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">6. Data Retention</h2>
      <p className="mb-4">
        We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
      </p>

      <h2 className="text-2xl font-semibold mb-2">7. Children's Privacy</h2>
      <p className="mb-4">
        The Service is not intended for children under 18, and we do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently collected such information, we will take steps to delete it.
      </p>

      <h2 className="text-2xl font-semibold mb-2">8. Changes to this Privacy Policy</h2>
      <p className="mb-4">
        We may update this Privacy Policy from time to time. If we make material changes, we will notify you by posting the updated policy on our website or through other communication channels. Your continued use of the Service after such changes will constitute your acceptance of the revised policy.
      </p>

      <h2 className="text-2xl font-semibold mb-2">9. Contact Us</h2>
      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:contact@ride-share.app" className="link link-primary">contact@ride-share.app</a>.
      </p>
    </div>
  );
};

export default Privacy;
