import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';

export const calculateRouteOverlap = (trip1, trip2) => {
    const startMaxDistance = trip1.startRadius;
    const endMaxDistance = trip1.endRadius;

    // Calculate distances between start points and end points
    const startDistance = calculateDistance(trip1.startCoords, trip2.startCoords);
    const endDistance = calculateDistance(trip1.endCoords, trip2.endCoords);

    // Normalize the distances based on the maxDistance
    const normalizedStartOverlap = Math.max(0, 1 - (startDistance / startMaxDistance));
    const normalizedEndOverlap = Math.max(0, 1 - (endDistance / endMaxDistance));

    // Calculate the average overlap
    const averageOverlap = (normalizedStartOverlap + normalizedEndOverlap) / 2;

    // Return the overlap percentage
    return Math.round(averageOverlap * 100);
}

export const calculateTimeMatch = (trip1, trip2) => {
    const timeDifference = Math.abs(trip1.timestamp - trip2.timestamp) / (1000 * 60); // Difference in minutes
    const maxDifference = 7 * 24 * 60; // One week in minutes (7 days * 24 hours * 60 minutes)

    // Normalize the time difference to a percentage where 100% is same date/time and 0% is a week apart
    const timeMatch = Math.max(0, 100 - (timeDifference / maxDifference) * 100);

    return Math.round(timeMatch); // Return time match percentage
}

const calculateBoundingBox = (coords, radius) => {    
    const earthRadiusKm = 6371; // Earth's radius in kilometers
    const lat = coords.lat;
    const lng = coords.lng;

    // Convert radius from kilometers to degrees
    const latRadius = radius / 111.32; // 1 degree of latitude ≈ 111.32 km

    // Adjust for longitude based on latitude
    const lngRadius = radius / (111.32 * Math.cos(lat * Math.PI / 180));

    // Calculate bounding box
    const minLat = lat - latRadius;
    const maxLat = lat + latRadius;
    const minLng = lng - lngRadius;
    const maxLng = lng + lngRadius;

    return { minLat, maxLat, minLng, maxLng };
}

const calculateDistance = (coords1, coords2) => {
    const R = 6371; // Radius of the Earth in kilometers
    const dLat = (coords2.lat - coords1.lat) * Math.PI / 180;
    const dLng = (coords2.lng - coords1.lng) * Math.PI / 180;
    const lat1 = coords1.lat * Math.PI / 180;
    const lat2 = coords2.lat * Math.PI / 180;

    const a = 
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Distance in kilometers
}

export const getUserInfo = async (uid) => {
    try {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', uid); // Reference to the user's document
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
            return userDoc.data().name; // Return the user's name
        }
        console.error(`No user found with uid: ${uid}`);
        return 'Unknown'; // Default name if not found
    } catch (error) {
        console.error('Error fetching user data:', error);
        return 'Unknown';
    }
}

const searchTrips = async (currentTrip) => {
    try {
        const { startCoords, endCoords, startRadius, endRadius } = currentTrip;
        const db = getFirestore();
        
        // Calculate bounding boxes for start and end locations
        const startBox = calculateBoundingBox(startCoords, startRadius);
        const endBox = calculateBoundingBox(endCoords, endRadius);        
        
        // Firestore query to get trips within bounding boxes
        const tripsCollection = collection(db, 'trips');
        const q = query(
            tripsCollection,
            where('startCoords.lat', '>=', startBox.minLat),
            where('startCoords.lat', '<=', startBox.maxLat),
            where('startCoords.lng', '>=', startBox.minLng),
            where('startCoords.lng', '<=', startBox.maxLng),
            where('endCoords.lat', '>=', endBox.minLat),
            where('endCoords.lat', '<=', endBox.maxLat),
            where('endCoords.lng', '>=', endBox.minLng),
            where('endCoords.lng', '<=', endBox.maxLng),
            where('uid', '!=', currentTrip.uid)
        );

        const querySnapshot = await getDocs(q);
        
        // Process trips into the desired match structure
        const matches = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
                    const trip = doc.data();
                    const name = await getUserInfo(trip.uid);
                    const routeOverlap = calculateRouteOverlap(currentTrip, trip);
                    const timeMatch = calculateTimeMatch(currentTrip, trip);
                    const returnObject = {
                        name, 
                        routeOverlap,
                        timeMatch,
                        tripDetails: {
                            id: doc.id,
                            ...trip
                        }
                    }
                    return returnObject;
            })
        );
        
        return matches;
    } catch (error) {
        console.error('Error fetching trips:', error);
        throw error;
    }
}

export default searchTrips;



