import React from 'react';
import { Link } from 'react-router-dom';

const BlogPage = () => {
  const posts = [
    {
      id: 1,
      title: "The Ultimate Guide to Ride Sharing for Travelers",
      excerpt:
        "Discover how ride sharing can transform your travel experience, from saving money to reducing your carbon footprint. Learn how to find ride-sharing companions and make your trips more meaningful.",
      link: "/blog/the-ultimate-guide-to-ride-sharing",
    },
    {
      id: 2,
      title: "5 Reasons Why Ride Sharing is the Future of Travel",
      excerpt:
        "Explore why ride sharing is becoming the go-to solution for affordable, eco-friendly travel. Discover the key benefits of ride sharing and how it can change the way you travel.",
      link: "/blog/5-reasons-why-ride-sharing-is-the-future",
    },
    {
      id: 3,
      title: "How Ride Sharing Helps You Save Money on Long-Distance Trips",
      excerpt:
        "Long-distance trips can be expensive, but ride sharing offers an affordable alternative. Find out how you can cut costs on fuel, tolls, and more by sharing your ride.",
      link: "/blog/how-ride-sharing-saves-money",
    },
    {
        id: 4,
        title: "5 Tips for Planning a Shared Ride for Your Next Vacation",
        excerpt:
          "Planning a vacation? Learn five practical tips for organizing a shared ride, from posting your trip details early to choosing the right ride-sharing platform.",
        link: "/blog/5-tips-for-planning-a-shared-ride-for-vacation",
    },
    {
        id: 5,
        title: "How to Find Travel Companions for Long-Distance Rides",
        excerpt:
          "Looking for travel companions for a long-distance ride? Learn how to use ride-sharing apps to find the perfect travel partners, share costs, and make your journey more enjoyable.",
        link: "/blog/how-to-find-travel-companions-for-long-distance-rides",
      },
      {
        id: 6,
        title: "The Eco-Friendly Benefits of Ride Sharing",
        excerpt:
          "Discover how ride sharing reduces carbon emissions, conserves resources, and promotes sustainable travel. Learn the eco-friendly benefits of sharing rides for your next trip.",
        link: "/blog/eco-friendly-benefits-of-ride-sharing",
      },
      {
        id: 7,
        title: "How to Stay Safe While Ride Sharing",
        excerpt:
          "Learn how to stay safe while using ride-sharing services with these practical tips. From choosing a trusted platform to securing your belongings, here's how to protect yourself during a shared ride.",
        link: "/blog/how-to-stay-safe-while-ride-sharing",
      },
      {
        id: 8,
        title: "The Best Ride-Sharing Apps for Travelers",
        excerpt:
          "Discover the top ride-sharing apps for travelers. From long-distance trips to city commutes, these platforms offer affordable, convenient, and eco-friendly travel options.",
        link: "/blog/best-ride-sharing-apps-for-travelers",
      },
      {
        id: 9,
        title: "How Ride Sharing Reduces Your Carbon Footprint",
        excerpt:
          "Learn how ride sharing helps reduce carbon emissions, lower fuel consumption, and promote eco-friendly travel habits. Discover how you can minimize your environmental impact by sharing rides.",
        link: "/blog/how-ride-sharing-reduces-your-carbon-footprint",
      },
      {
        id: 10,
        title: "Ride Sharing vs. Car Rentals: Which is Better for Travelers?",
        excerpt:
          "Trying to decide between ride sharing or renting a car for your next trip? This post compares the cost, convenience, and environmental impact of both options to help you choose the best travel option.",
        link: "/blog/ride-sharing-vs-car-rentals-which-is-better",
      }
      
      
  ];

  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-3xl mx-auto space-y-12">
        <h1 className="text-5xl font-extrabold text-blue-600 text-center mb-12">
          Ride Share Blog
        </h1>

        {posts.map((post) => (
          <div key={post.id} className="bg-white shadow-md rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-semibold text-gray-900 mb-4">
              {post.title}
            </h2>
            <p className="text-lg text-gray-700 mb-4">{post.excerpt}</p>
            <Link to={post.link}>
              <button className="btn btn-primary btn-lg px-6 py-3 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
                Read More
              </button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
