import React, { createContext, useState, useEffect, useContext } from 'react';
import { onSnapshot, collection, query, where, orderBy, getDoc, getDocs, limit, doc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Create a NotificationsContext to hold both the chat and notification states
const NotificationsContext = createContext();

// Helper function to get unread status of chats and participant names
const getChatsWithUnreadStatus = async (chatDocs, db, user) => {
  return await Promise.all(
    chatDocs.map(async (chat) => {
      const otherParticipants = Object.keys(chat.participants).filter((id) => id !== user.uid)

      // Fetch names of the other participants
      const participantNames = await Promise.all(
        otherParticipants.map(async (participantUID) => {
          const userDocRef = doc(db, 'users', participantUID);
          const userDoc = await getDoc(userDocRef);
          return userDoc.exists() ? userDoc.data().name : 'Unknown User';
        })
      );

      // Get the latest message in the chat
      const messagesRef = collection(db, 'chats', chat.id, 'messages');
      const latestMessageQuery = query(messagesRef, orderBy('timestamp', 'desc'), limit(1));
      const latestMessageSnapshot = await getDocs(latestMessageQuery);

      // Filter out chats that have no messages
      if (latestMessageSnapshot.empty) {
        return null; // Return null to indicate this chat should be filtered out
      }

      const latestMessageId = latestMessageSnapshot.docs[0]?.id;
      let hasUnreadMessages = false;

      // Check if the last read message is different from the latest message
      if (latestMessageId && chat.lastReadMessageId && chat.lastReadMessageId[user.uid]) {
        const lastReadMessageId = chat.lastReadMessageId[user.uid];
        hasUnreadMessages = latestMessageId !== lastReadMessageId;
      } else if (latestMessageId) {
        // If no lastReadMessageId exists for the user, consider all messages unread
        hasUnreadMessages = true;
      }

      return { ...chat, participantNames, hasUnreadMessages, otherParticipants };
    })
  );
};

export const NotificationsProvider = ({ children }) => {
  const [activeChats, setActiveChats] = useState([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();

    let chatUnsubscribe;
    let notificationsUnsubscribe;

    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);

      // Cleanup any existing listeners when the user logs out
      if (!currentUser) {
        if (chatUnsubscribe) chatUnsubscribe();
        if (notificationsUnsubscribe) notificationsUnsubscribe();
        setActiveChats([]);
        setNotifications([]);
        setHasUnreadMessages(false);
        setLoading(false);
        return;
      }

      // ----- Chat listener with the correct query -----
      const chatQuery = query(
        collection(db, 'chats'),
        where(`participants.${currentUser.uid}`, '==', true)
      );

      chatUnsubscribe = onSnapshot(chatQuery, async (snapshot) => {
        const chatDocs = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const chatsWithUnreadStatus = await getChatsWithUnreadStatus(chatDocs, db, currentUser);
        const filteredChats = chatsWithUnreadStatus.filter((chat) => chat !== null);

        setActiveChats(filteredChats);

        const unreadMessages = filteredChats.some((chat) => chat?.hasUnreadMessages);
        setHasUnreadMessages(unreadMessages);
      });

      // ----- Notification listener -----
      const notificationsRef = collection(db, 'users', currentUser.uid, 'notifications');
      const notificationsQuery = query(notificationsRef, orderBy('timestamp', 'desc'));

      notificationsUnsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
        const notificationsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setNotifications(notificationsData);
        setLoading(false);
      });
    });

    // Cleanup on component unmount
    return () => {
      if (chatUnsubscribe) chatUnsubscribe();
      if (notificationsUnsubscribe) notificationsUnsubscribe();
      unsubscribeAuth();
    };
  }, []);

  // If no user is logged in, return default values
  if (!user) {
    return (
      <NotificationsContext.Provider value={{ activeChats: [], hasUnreadMessages: false, notifications: [], loading: false }}>
        {children}
      </NotificationsContext.Provider>
    );
  }

  return (
    <NotificationsContext.Provider value={{ activeChats, hasUnreadMessages, notifications, loading }}>
      {children}
    </NotificationsContext.Provider>
  );
};

// Helper function to access the notifications context
export const useNotifications = () => useContext(NotificationsContext);
