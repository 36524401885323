import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Title and Intro Section */}
        <h1 className="text-5xl font-extrabold text-blue-600 text-center">
          5 Tips for Planning a Shared Ride for Your Next Vacation
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Long-distance travel can be costly, but with ride sharing, you can reduce expenses and make your trip more affordable. Here’s how ride sharing helps you save on your next journey.
        </p>

        {/* Tip 1: Post Your Trip Early */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            1. Post Your Trip Details Early
          </h2>
          <p className="text-lg text-gray-700">
            The earlier you post your trip details on a <strong>ride-sharing app</strong> like Ride Share, the better chance you have of finding the perfect travel companions. Posting early allows potential riders to match with you based on their schedules.
          </p>
          <p className="text-lg text-gray-700">
            Include key information such as:
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li>Departure and destination points</li>
            <li>Date and time of travel</li>
            <li>Flexibility in timing, if any</li>
          </ul>
          <p className="text-lg text-gray-700">
            Posting these details upfront helps you match with travelers who share your plans, ensuring a more efficient and stress-free trip.
          </p>
        </section>

        {/* Tip 2: Choose the Right Ride-Sharing Platform */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            2. Choose the Right Ride-Sharing Platform
          </h2>
          <p className="text-lg text-gray-700">
            Not all <strong>ride-sharing services</strong> are created equal. Some focus on local commutes, while others are designed for <strong>long-distance travel</strong>. Make sure to choose a platform like Ride Share that connects travelers for <strong>vacation carpooling</strong> or road trips.
          </p>
        </section>

        {/* Tip 3: Communicate Clearly with Your Travel Companions */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            3. Communicate Clearly with Your Travel Companions
          </h2>
          <p className="text-lg text-gray-700">
            Once you've found a match, clear communication is key. Discuss important details such as:
          </p>
          <ul className="list-disc list-inside space-y-4">
            <li>Pickup and drop-off locations</li>
            <li>Travel preferences (stops, driving responsibilities)</li>
            <li>Costs (fuel, tolls, other travel expenses)</li>
          </ul>
          <p className="text-lg text-gray-700">
            Using Ride Share’s built-in messaging system makes it easy to coordinate with your travel partners.
          </p>
        </section>

        {/* Tip 4: Plan Scenic Stops Along the Way */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            4. Plan Scenic Stops Along the Way
          </h2>
          <p className="text-lg text-gray-700">
            One of the best parts of <strong>ride-sharing for vacations</strong> is the flexibility to plan scenic stops. Discuss with your travel companions if they’d like to visit any points of interest during the trip. These stops can turn your journey into a fun and memorable part of the vacation.
          </p>
        </section>

        {/* Tip 5: Be Eco-Conscious and Travel Light */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            5. Be Eco-Conscious and Travel Light
          </h2>
          <p className="text-lg text-gray-700">
            Ride sharing is one of the most <strong>eco-friendly ways to travel</strong>. You can further minimize your environmental impact by traveling light. Efficient packing reduces fuel usage and ensures there's enough room for everyone's luggage.
          </p>
          <p className="text-lg text-gray-700">
            Consider sharing essentials such as coolers or travel gear to reduce bulk and build camaraderie among your travel companions.
          </p>
        </section>

        {/* Conclusion Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Conclusion
          </h2>
          <p className="text-lg text-gray-700">
            Planning a <strong>shared ride for your vacation</strong> can be fun, affordable, and eco-friendly. By following these tips—posting early, choosing the right platform, communicating clearly, planning scenic stops, and packing light—you can ensure a smooth, enjoyable, and budget-friendly journey.
          </p>
          <p className="text-lg text-gray-700">
            Ready to plan your next trip? <Link to="/start" className="text-blue-500 font-bold">Sign up for Ride Share</Link> today and connect with travelers heading in the same direction!
          </p>
        </section>

      </div>
    </div>
  );
};

export default BlogPost;
