export const faqs = [
    {
      question: 'What is Ride Share, and how does this ride-sharing app work?',
      answer: 'Ride Share is a carpooling app that helps travelers find rides with others going in the same direction. Simply post your trip details or search for available rides, connect with fellow travelers, and enjoy eco-friendly, affordable travel.'
    },
    {
      question: 'Is Ride Share free to use?',
      answer: 'Yes! Ride Share is free to sign up and use. You only split the travel costs with the people you carpool with, making this one of the most affordable ride-sharing solutions for travelers.'
    },
    {
      question: 'How do I find a ride or post my trip?',
      answer: 'To find a ride, just enter your start location, destination, and travel date. Ride Share will show you available trips with travelers heading in your direction. You can also post your own trip to find travel companions for your carpool.'
    },
    {
      question: 'Is Ride Share safe for travelers?',
      answer: 'Ride Share prioritizes safety. Each user has a profile, and you can review feedback and ratings from past trips. Travelers are encouraged to communicate with each other before meeting, making the ride-sharing experience secure and reliable.'
    },
    {
      question: 'How does carpooling with Ride Share help the environment?',
      answer: 'Ride Share promotes eco-friendly carpooling by reducing the number of cars on the road. Fewer cars mean less pollution and traffic congestion, making ride-sharing an eco-friendly and sustainable travel option for travelers who care about the planet.'
    },
    {
      question: 'Can I use Ride Share for long-distance trips?',
      answer: 'Yes! Ride Share is perfect for long-distance trips. Whether you’re planning a road trip across the country or traveling between cities, you can post your trip and connect with travelers looking for carpooling companions.'
    },
    {
      question: 'How do I communicate with my ride match?',
      answer: 'Once Ride Share matches you with a fellow traveler, you can chat with them directly through the platform to coordinate trip details, like meeting points, travel times, and cost-sharing arrangements for the ride.'
    },
    {
      question: 'What happens if my driver or rider cancels?',
      answer: 'If your ride match cancels, you can quickly find another ride using the Ride Share app. To avoid last-minute changes, we recommend confirming all trip details with your carpooling partners ahead of time.'
    }
  ];
  