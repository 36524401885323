import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, deleteDoc } from 'firebase/firestore';

const DeleteButton = ({ tripId }) => {
  const nav = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false); // State to manage the visibility of the confirmation overlay

  const handleDelete = async () => {
    setLoading(true);
    try {
      const docRef = doc(getFirestore(), 'trips', tripId);
      await deleteDoc(docRef);
      nav('/my-trips');
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const handleConfirm = () => {
    setShowConfirm(true); // Show the confirmation overlay
  };

  const handleCancel = () => {
    setShowConfirm(false); // Hide the confirmation overlay
  };

  return (
    <div className='relative w-full'>
      <button onClick={handleConfirm} className="btn btn-error w-full" disabled={loading}>
        {loading ? 'Deleting trip...' : 'Delete Trip'}
      </button>

      {/* Confirmation Overlay */}
      {showConfirm && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] max-w-md">
            <h2 className="text-lg font-bold mb-4">Are you sure you want to delete this trip?</h2>
            <p className="text-gray-600 mb-4">This action cannot be undone.</p>
            <div className="flex justify-end space-x-4">
              <button onClick={handleCancel} className="btn btn-secondary">
                Cancel
              </button>
              <button onClick={handleDelete} className="btn btn-error" disabled={loading}>
                {loading ? 'Deleting...' : 'Yes, Delete'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteButton;
