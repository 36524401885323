import React from 'react';

const DescriptionInput = ({ description, setDescription }) => {
  return (
    <div className="mb-4">
      <textarea
        id="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="More details (optional)"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        rows="4"
      />
    </div>
  );
};

export default DescriptionInput;
