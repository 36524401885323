import React from 'react';
import { Link } from 'react-router-dom';

const BlogPost = () => {
  return (
    <div className="bg-gray-50 py-16 px-6 lg:px-24">
      <div className="max-w-4xl mx-auto space-y-12">
        {/* Title and Intro Section */}
        <h1 className="text-5xl font-extrabold text-blue-600 text-center">
          How Ride Sharing Reduces Your Carbon Footprint
        </h1>
        <p className="text-xl text-gray-700 text-center max-w-3xl mx-auto">
          Ride sharing is a powerful way to reduce emissions, lower fuel consumption, and help create a more sustainable future. Discover how sharing rides can significantly reduce your carbon footprint.
        </p>

        {/* Section 1: Fewer Cars on the Road Means Less Emissions */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            1. Fewer Cars on the Road Means Less Emissions
          </h2>
          <p className="text-lg text-gray-700">
            Every vehicle on the road contributes to air pollution and carbon emissions. By opting for <strong>ride sharing</strong>, fewer cars are needed to transport people, reducing the total number of vehicles on the road and significantly cutting down on emissions.
          </p>
          <p className="text-lg text-gray-700">
            Carpooling can reduce carbon emissions by 55-75%, making it one of the most effective ways to reduce transportation-related emissions.
          </p>
        </section>

        {/* Section 2: Lower Fuel Consumption */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            2. Lower Fuel Consumption
          </h2>
          <p className="text-lg text-gray-700">
            Fewer vehicles on the road mean lower fuel consumption. Sharing rides for both short and long-distance trips helps conserve fuel, as the environmental and economic costs are spread across all passengers.
          </p>
        </section>

        {/* Section 3: Reducing Traffic Congestion */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            3. Reducing Traffic Congestion
          </h2>
          <p className="text-lg text-gray-700">
            Ride sharing reduces the number of vehicles on the road, which helps minimize traffic congestion. Less congestion means fewer emissions caused by stop-and-go traffic, leading to a more efficient and eco-friendly transportation system.
          </p>
        </section>

        {/* Section 4: Maximizing Vehicle Efficiency */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            4. Maximizing Vehicle Efficiency
          </h2>
          <p className="text-lg text-gray-700">
            A vehicle carrying only one person is not using its full capacity, making it an inefficient way to travel. <strong>Ride sharing</strong> maximizes vehicle efficiency by filling seats with multiple passengers, reducing the per-person environmental impact of each trip.
          </p>
        </section>

        {/* Section 5: Promoting Sustainable Travel Habits */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            5. Promoting Sustainable Travel Habits
          </h2>
          <p className="text-lg text-gray-700">
            One of the long-term benefits of <strong>ride sharing</strong> is its promotion of sustainable travel habits. By choosing to share rides instead of driving alone, you’re making a conscious decision to reduce your environmental footprint, encouraging others to do the same.
          </p>
        </section>

        {/* Section 6: Supporting the Shift to Electric Vehicles (EVs) */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            6. Supporting the Shift to Electric Vehicles (EVs)
          </h2>
          <p className="text-lg text-gray-700">
            Many ride-sharing services are transitioning to electric vehicles (EVs) to further reduce emissions. EVs have zero tailpipe emissions, making them an even greener option when combined with ride sharing.
          </p>
        </section>

        {/* Conclusion Section */}
        <section className="space-y-6">
          <h2 className="text-3xl font-semibold text-gray-900">
            Conclusion
          </h2>
          <p className="text-lg text-gray-700">
            Ride sharing is an easy and effective way to reduce your carbon footprint. By sharing rides, you contribute to fewer emissions, less fuel consumption, and a more sustainable transportation system.
          </p>
          <p className="text-lg text-gray-700">
            Ready to make your next trip greener? <Link to="/register" className="text-blue-500 font-bold">Join Ride Share</Link> today and start reducing your carbon footprint with every ride!
          </p>
        </section>

        {/* Call to Action */}
        <div className="text-center py-8">
          <Link to="/register">
            <button className="btn btn-primary btn-lg px-8 py-4 font-bold rounded-full shadow-lg hover:scale-105 transition-transform duration-300 ease-in-out">
              Join Ride Share Now!
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogPost;
